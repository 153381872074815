import React, { useRef, useState } from "react";
import { Box, Button, Divider, Modal, Stack, Tooltip, Typography } from "@mui/material";
import axios from "axios";
import { APIUrlThree, APIUrlToken, APIUrlTwo, GetUserId } from "../../../Utils/Utils";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";
import reddoticon from "../../../Assets/reddoticon.svg";
import LinkedINImage from "../../../Assets/linkedin.svg";
import massageicon from "../../../Assets/FrameDiff.svg";
import company from "../../../Assets/people-logo.png";
import education from "../../../Assets/cap-logo.png";
import experience from "../../../Assets/jobcompany.png";
import Siderbardeleteicon from "../../../Assets/Delete.svg";
import "./Style.css";
import saveimg from "../../../Assets/downloadcv.png";
import print from "../../../Assets/print.svg"
import { useReactToPrint } from 'react-to-print';
import html2pdf from 'html2pdf.js';
import { ClearIcon } from "@mui/x-date-pickers";
import LabelInput from "../../LabelInputFields/Index";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';


export default function AllAiPaths({ pathName, setPathName, dataShortestPath, setDataShortestPath, sourceuid, targetuid, getSavedAiPath, editData, viewData, setViewData, setEditData,
  setsourceuid, setFromalldata, settargetuid, setToinput
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    borderRadius: "8px",
  };
  const initialNodes = dataShortestPath;  
  const [buttonVisible, setButtonVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonVisiblenew, setbuttonVisiblenew] = useState(false);
  const loggedInUserId = GetUserId();
  const [alternativePath, setAlternativePath] = React.useState([]);
  const [openNameModal, setOpenNameModal] = useState(false)
  const toggleButtonVisibility = () => {
    setButtonVisible(!buttonVisible);
    setbuttonVisiblenew(!buttonVisiblenew);
  };
  function compareNodes(node1, node2) {
    return node1.uid === node2.uid;
  }
  const filterUniqueArrays = (data) => {
    const uniqueArrays = new Set();
    return data.filter((subArray) => {
      const jsonString = JSON.stringify(subArray);
      if (uniqueArrays.has(jsonString)) {
        return false;
      }
      uniqueArrays.add(jsonString);
      return true;
    });
  };
  const handleDeleteNode = (item) => {
    setLoading(true);
    const data = {};
    data.source_uid = dataShortestPath[0]?.source;
    data.target_uid = dataShortestPath[0]?.target;
    const arr = [item?.uid];
    data.nodes_uid = arr;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      url: `${APIUrlTwo()}/v1/remove_node`,
      data: JSON.stringify(data),
    };
    axios(option)
      .then((response) => {
        setLoading(false);
        setButtonVisible(!buttonVisible);
        if (response?.data[0]?.bestPathInfor?.message !== "Have Path") {
          toast.error("No further paths available");
        }
        if (alternativePath?.length > 0) {
          const data = [...alternativePath, response.data?.[0]]
          const filtered = filterUniqueArrays(data)
          const filterWeighted = filtered.filter(item => {

            const bestPathNodes = initialNodes?.[0]?.bestPathInfor?.nodes || [];
            const itemNodes = item?.bestPathInfor?.nodes || [];

            if (bestPathNodes.length !== itemNodes.length) return true;

            return !bestPathNodes.every((node, index) => compareNodes(node, itemNodes[index]));
          });
          if (alternativePath?.length == filterWeighted?.length) {
            toast.error("No further paths available");
          }
          setAlternativePath(filterWeighted);
        } else {
          alternativePath.push(response.data?.[0]);
        }
        if (response?.status === 200) {
          toast.success(response?.data?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message || "No further paths available");
      });
  };

  function combineNodesAndEdges(nodes, edges) {
    const combinedArray = [];
    edges?.forEach((edge) => {
      const sourceNode = nodes?.find((node) => node.uid === edge.source);
      const targetNode = nodes?.find((node) => node.uid === edge.target);
      if (sourceNode && targetNode) {
        combinedArray?.push({
          ...sourceNode,
          ...edge,
          target: targetNode.uid,
        });
      }
    });
    const lastNode = nodes?.find(
      (node) => !edges.some((edge) => edge.source === node.uid)
    );
    if (lastNode) {
      combinedArray.push(lastNode);
    }
    return combinedArray;
  }

  // AlternativePath
  let addingAlternate = [];
  alternativePath?.forEach((item) => {
    const upcomingAlternate = combineNodesAndEdges(
      item?.bestPathInfor?.nodes,
      item?.bestPathInfor?.edges
    );
    let updatedAlternate = [];
    let newAlternate = [];
    upcomingAlternate?.forEach((item, index) => {
      updatedAlternate?.push(item);
      if ((index + 1) % 4 === 0 || index === upcomingAlternate?.length - 1) {
        newAlternate?.push(updatedAlternate);
        updatedAlternate = [];
      }
    });
    addingAlternate.push(newAlternate);
  });

  // best path
  const BestPathNodes = combineNodesAndEdges(
    initialNodes?.[0]?.bestPathInfor?.nodes,
    initialNodes?.[0]?.bestPathInfor?.edges
  );
  const bestPathinnodes = [];
  let tempArray = [];
  BestPathNodes?.forEach((item, index) => {
    tempArray?.push(item);
    if ((index + 1) % 4 === 0 || index === BestPathNodes?.length - 1) {
      bestPathinnodes?.push(tempArray);
      tempArray = [];
    }
  });
  //shortpath
  const ShortPathNodes = combineNodesAndEdges(
    initialNodes?.[0]?.shortestPath?.nodes,
    initialNodes?.[0]?.shortestPath?.edges
  );
  const shortPathinnodes = [];
  let tempArray2 = [];
  ShortPathNodes?.forEach((item, index) => {
    tempArray2?.push(item);
    if ((index + 1) % 4 === 0 || index === ShortPathNodes?.length - 1) {
      shortPathinnodes?.push(tempArray2);
      tempArray2 = [];
    }
  });
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const handleToPDF = () => {
    const content = componentRef.current;
    const options = {
      filename: 'aiPath.pdf',
      margin: 10,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: {
        format: 'letter',
        orientation: 'landscape',
      },
    };
    html2pdf().set(options).from(content).save();
  };
  const handleSavePath = () => {
    if (pathName?.length < 1) {
      toast.error("Please enter path name")
      return
    }
    setLoading(true);
    const data = [
      {
        "id": 0,
        "user_id": Number(loggedInUserId),
        "json_result": dataShortestPath,
        "path_name": pathName,
        "created_dated": new Date(),
        "source_id": Number(sourceuid),
        "destination_id": Number(targetuid)
      }
    ];
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      data: JSON.stringify(data),
      url: `${APIUrlThree()}/v1/save_aipath`,
    };
    axios(option)
      .then((response) => {
        setLoading(false);
        setOpenNameModal(false);
        setPathName("");
        setDataShortestPath()
        getSavedAiPath()
        toast.success("Path saved successfully");

      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  }
  const handleUpdatePath = () => {
    if (pathName?.length < 1) {
      toast.error("Please enter path name")
      return
    }
    setLoading(true);
    const data = {
      "id": 0,
      "user_id": Number(loggedInUserId),
      "json_result": dataShortestPath,
      "path_name": pathName,
      "created_dated": new Date(),
      "source_id": Number(sourceuid),
      "destination_id": Number(targetuid)
    }
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      data: JSON.stringify([data]),
      url: `${APIUrlThree()}/v1/update_aipath?aipathId=${editData?.id}`,
    };
    axios(option)
      .then((response) => {
        setLoading(false);
        setOpenNameModal(false);
        setPathName("");
        setDataShortestPath()
        getSavedAiPath()
        setEditData()
        setViewData()
        setsourceuid();
        setFromalldata("")
        settargetuid()
        setToinput("")

        toast.success("Path updated successfully");

      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  }
  return (
    <>
      {loading && <Loader />}
      {initialNodes?.length && initialNodes?.[0]?.message !== "No Path Found" ? (
        <div className="main-container-ofAll-ai-Paths">
          <div className="submain-container">
            <div className="outer-container-of-ai-paths">
              <div className="Siderbar--inner-container">
                <h3>All AI Paths</h3>
              </div>
              <div className="AllAi-paths-button">
                <div className="download-icon-path" onClick={handlePrint} ><img src={print} alt="" className='print-img-aipath-table' /></div>
                <div className="download-icon-path" onClick={handleToPDF}><img src={saveimg} alt="" /></div>
                <button onClick={toggleButtonVisibility}>
                  {buttonVisible
                    ? "Disable Alternate Paths"
                    : "Enable Alternate Paths"}
                </button>

              </div>
            </div>
            {/* Alternate path  */}
            <div ref={componentRef} className="print-div-full" >
              {addingAlternate.slice().reverse().map((myItem, index) => {
                return myItem.length ? (
                  <>

                    <div className="AllAIOuttercontainer-sidebar" key={index} style={{ pageBreakInside: "avoid" }} >
                      <div className="AllAi-paths-heading">
                        {/* <h3 className="path-heading-sidebar">Alternate Path {value === 0 ? "" : value}</h3> */}
                        <h3 className="path-heading-sidebar">Alternate Path {addingAlternate.length - index}</h3>
                      </div>
                      <div className="AllAi-paths-list">
                        {myItem?.map((item, index) => (
                          <>
                            <div className="siderbar-row">
                              {item?.map((i, idx) => (
                                <div
                                  style={{ display: "flex" }}
                                  className="innerData_ai_cont"
                                >
                                  <div
                                    key={idx}
                                    className="remove-username-container2"
                                    style={{ display: "flex" }}
                                  >
                                    <div className="delete-contaoner2">
                                      <div className="delete-button-down2">
                                        <Tooltip title={<span dangerouslySetInnerHTML={{ __html: `${i.company ? i.company : ""}<br>${i.email ? i.email : ""}` }} />}>
                                          <div
                                            className={`sider-var-list-data-button-sidebar`}
                                          >
                                            <img
                                              src={reddoticon}
                                              alt=""
                                              className="red-doticon-image-sidebar"
                                            />
                                            <p className="identity-name">{i.name}</p>
                                          </div>
                                        </Tooltip>
                                      </div>
                                      {buttonVisible &&
                                        !(index === 0 && idx === 0) &&
                                        !(
                                          index ===
                                          myItem?.length - 1 &&
                                          idx === item?.length - 1
                                        ) && (
                                          <div className="setthedeletebutton2">
                                            <button
                                              className="DeleteButtonVisible"
                                              onClick={() =>
                                                handleDeleteNode(i)
                                              }
                                            >
                                              <img
                                                src={Siderbardeleteicon}
                                                alt=""
                                              />
                                              Remove
                                            </button>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                  {i?.relation && (
                                    <div className="relation_ai_path_cont">
                                      <hr className="horizontal-line-in-aipath" />
                                      <div className="siderbar-image-container">
                                        <div className="Siderbar-lits-images">
                                          <Tooltip
                                            title={
                                              "Weighted Connection Strength"
                                            }
                                          >
                                            <div className="weight-between-nodes">
                                              {i?.weight}
                                            </div>
                                          </Tooltip>
                                          <Tooltip title={"Email Exchanged"}>
                                            {i?.relation?.includes(
                                              "Email"
                                            ) && (
                                                <img
                                                  src={massageicon}
                                                  alt=""
                                                  className="siderbar-modal-img"
                                                />
                                              )}
                                          </Tooltip>
                                          <Tooltip
                                            title={"LinkedIn Connection"}
                                          >
                                            {i?.relation?.includes(
                                              "LinkedIn"
                                            ) && (
                                                <img
                                                  src={LinkedINImage}
                                                  alt=""
                                                  className="siderbar-modal-img"
                                                />
                                              )}
                                          </Tooltip>
                                          <Tooltip
                                            title={"Working in Same Organization"}
                                          >
                                            {i?.relation?.includes(
                                              "Organization"
                                            ) && (
                                                <img
                                                  src={company}
                                                  alt=""
                                                  className="siderbar-modal-img"
                                                />
                                              )}
                                          </Tooltip>
                                          <Tooltip
                                            title={"Attended Same College"}
                                          >
                                            {i?.relation?.includes(
                                              "Education"
                                            ) && (
                                                <img
                                                  src={education}
                                                  alt=""
                                                  className="siderbar-modal-img"
                                                />
                                              )}
                                          </Tooltip>
                                          <Tooltip
                                            title={
                                              "Previously Worked in Same Company"
                                            }
                                          >
                                            {i?.relation?.includes(
                                              "Experience"
                                            ) && (
                                                <img
                                                  src={experience}
                                                  alt=""
                                                  className="siderbar-modal-img"
                                                />
                                              )}
                                          </Tooltip>
                                          <Tooltip
                                            title={
                                              "Meeting"
                                            }
                                          >
                                            {i?.relation?.includes(
                                              "Meeting"
                                            ) && (
                                                <CalendarMonthIcon className="siderbar-modal-img" />
                                              )}
                                          </Tooltip>
                                        </div>
                                      </div>
                                      <div className="connecting-line"></div>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                            {index < myItem?.length - 1 && (
                              <div className="container-of-joint-line-of-node2-sidebar">
                                <p className="top-line-of-nodes2"></p>
                                <div className="main-for-nodes-line2"></div>
                                <p className="bottom-line-of-nodes2"></p>
                              </div>
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                    <Divider className="divider-for-seperator-section-sidebar" />
                  </>
                ) : null;
              })}
              {/* Weighted path */}
              <div className="AllAIOuttercontainer" style={{ pageBreakInside: "avoid" }} >
                <div className="AllAi-paths-heading">
                  <h3> Weighted Path</h3>
                </div>
                <div className="AllAi-paths-list">
                  {bestPathinnodes?.map((item, index) => (
                    <>
                      <div className="siderbar-row">
                        {item?.map((i, idx) => (
                          <div
                            style={{ display: "flex" }}
                            className="innerData_ai_cont"
                          >
                            <div
                              key={idx}
                              className="remove-username-container2"
                              style={{ display: "flex" }}
                            >
                              <div className="delete-contaoner2">
                                <div className="delete-button-down2">
                                  <Tooltip title={<span dangerouslySetInnerHTML={{ __html: `${i.company}<br>${i.email}` }} />}>

                                    <div className={`sider-var-list-data-button`}>
                                      <img
                                        src={reddoticon}
                                        alt=""
                                        className="red-doticon-image"
                                      />
                                      <p className="identity-name">{i.name}</p>
                                    </div>
                                  </Tooltip>
                                </div>
                                {buttonVisible &&
                                  !(index === 0 && idx === 0) &&
                                  !(
                                    index === bestPathinnodes?.length - 1 &&
                                    idx === item?.length - 1
                                  ) && (
                                    <div className="setthedeletebutton2">
                                      <button
                                        className="DeleteButtonVisible"
                                        onClick={() => handleDeleteNode(i)}
                                      >
                                        <img src={Siderbardeleteicon} alt="" />
                                        Remove
                                      </button>
                                    </div>
                                  )}
                              </div>
                            </div>
                            {i?.relation && (
                              <div className="relation_ai_path_cont">
                                <div>

                                  <hr className="horizontal-line-in-aipath" />
                                </div>
                                <div className="siderbar-image-container">
                                  <div className="Siderbar-lits-images">
                                    <Tooltip
                                      title={"Weighted Connection Strength"}
                                    >

                                      <div className="weight-between-nodes">
                                        {i?.weight}
                                      </div>
                                    </Tooltip>
                                    <Tooltip title={"Email Exchanged"}>
                                      {i?.relation?.includes("Email") && (
                                        <img
                                          src={massageicon}
                                          alt=""
                                          className="siderbar-modal-img"
                                        />
                                      )}
                                    </Tooltip>
                                    <Tooltip title={"LinkedIn Connection"}>
                                      {i?.relation?.includes("LinkedIn") && (
                                        <img
                                          src={LinkedINImage}
                                          alt=""
                                          className="siderbar-modal-img"
                                        />
                                      )}
                                    </Tooltip>
                                    <Tooltip title={"Working in Same Organization"}>
                                      {i?.relation?.includes("Organization") && (
                                        <img
                                          src={company}
                                          alt=""
                                          className="siderbar-modal-img"
                                        />
                                      )}
                                    </Tooltip>
                                    <Tooltip title={"Attended Same College"}>
                                      {i?.relation?.includes("Education") && (
                                        <img
                                          src={education}
                                          alt=""
                                          className="siderbar-modal-img"
                                        />
                                      )}
                                    </Tooltip>
                                    <Tooltip
                                      title={"Previously Worked in Same Company"}
                                    >

                                      {i?.relation?.includes("Experience") && (
                                        <img
                                          src={experience}
                                          alt=""
                                          className="siderbar-modal-img"
                                        />
                                      )}
                                    </Tooltip>
                                    <Tooltip
                                      title={
                                        "Meeting"
                                      }
                                    >
                                      {i?.relation?.includes(
                                        "Meeting"
                                      ) && (
                                          <CalendarMonthIcon className="siderbar-modal-img" />
                                        )}
                                    </Tooltip>
                                  </div>
                                </div>
                                <div className="connecting-line"></div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                      {index < bestPathinnodes?.length - 1 && (
                        <div className="container-of-joint-line-of-node2">
                          <p className="top-line-of-nodes2"></p>
                          <div className="main-for-nodes-line2"></div>
                          <p className="bottom-line-of-nodes2"></p>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
              <Divider className="divider-for-seperator-section" />

              {/* Shortest path */}
              <div className="AllAIOuttercontainer" style={{ pageBreakInside: "avoid" }} >
                <div className="AllAi-paths-heading">
                  <h3>Simple Path</h3>
                </div>
                <div className="AllAi-paths-list">
                  {shortPathinnodes?.map((item, index) => (
                    <>
                      <div className="siderbar-row">
                        {item?.map((i, idx) => (
                          <div
                            style={{ display: "flex" }}
                            className="innerData_ai_cont"
                          >
                            <div
                              key={idx}
                              className="remove-username-container2"
                              style={{ display: "flex" }}
                            >
                              <div className="delete-contaoner2">
                                <div className="delete-button-down2">
                                  <Tooltip title={<span dangerouslySetInnerHTML={{ __html: `${i.company}<br>${i.email}` }} />}>

                                    <div className={`sider-var-list-data-button`}>
                                      <img
                                        src={reddoticon}
                                        alt=""
                                        className="red-doticon-image"
                                      />
                                      <p className="identity-name">{i.name}</p>
                                    </div>
                                  </Tooltip>
                                </div>
                                {/* {buttonVisible &&
                                  !(index === 0 && idx === 0) &&
                                  !(
                                    index === shortPathinnodes?.length - 1 &&
                                    idx === item?.length - 1
                                  ) && (
                                    <div className="setthedeletebutton2">
                                      <button
                                        className="DeleteButtonVisible"
                                        onClick={() => handleDeleteNode(i)}
                                      >
                                        <img src={Siderbardeleteicon} alt="" />
                                        Remove
                                      </button>
                                    </div>
                                  )} */}
                              </div>
                            </div>
                            {i?.relation && (
                              <div className="relation_ai_path_cont">
                                <hr className="horizontal-line-in-aipath" />
                                <div className="siderbar-image-container">
                                  <div className="Siderbar-lits-images">
                                    <Tooltip
                                      title={"Weighted Connection Strength"}
                                    >

                                      <div className="weight-between-nodes">
                                        {i?.weight}
                                      </div>
                                    </Tooltip>
                                    <Tooltip title={"Email Exchanged"}>
                                      {i?.relation?.includes("Email") && (
                                        <img
                                          src={massageicon}
                                          alt=""
                                          className="siderbar-modal-img"
                                        />
                                      )}
                                    </Tooltip>
                                    <Tooltip title={"LinkedIn Connection"}>
                                      {i?.relation?.includes("LinkedIn") && (
                                        <img
                                          src={LinkedINImage}
                                          alt=""
                                          className="siderbar-modal-img"
                                        />
                                      )}
                                    </Tooltip>
                                    <Tooltip title={"Working in Same Organization"}>
                                      {i?.relation?.includes("Organization") && (
                                        <img
                                          src={company}
                                          alt=""
                                          className="siderbar-modal-img"
                                        />
                                      )}
                                    </Tooltip>
                                    <Tooltip title={"Attended Same College"}>
                                      {i?.relation?.includes("Education") && (
                                        <img
                                          src={education}
                                          alt=""
                                          className="siderbar-modal-img"
                                        />
                                      )}
                                    </Tooltip>
                                    <Tooltip
                                      title={"Previously Worked in Same Company"}
                                    >

                                      {i?.relation?.includes("Experience") && (
                                        <img
                                          src={experience}
                                          alt=""
                                          className="siderbar-modal-img"
                                        />
                                      )}
                                    </Tooltip>
                                    <Tooltip
                                      title={
                                        "Meeting"
                                      }
                                    >
                                      {i?.relation?.includes(
                                        "Meeting"
                                      ) && (
                                          <CalendarMonthIcon className="siderbar-modal-img" />
                                        )}
                                    </Tooltip>
                                  </div>
                                </div>
                                <div className="connecting-line"></div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                      {index < shortPathinnodes?.length - 1 && (
                        <div className="container-of-joint-line-of-node2">
                          <p className="top-line-of-nodes2"></p>
                          <div className="main-for-nodes-line2"></div>
                          <p className="bottom-line-of-nodes2"></p>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
            </div>
            <p className='ai-path-table-btn'>
              {
                viewData ? <Button variant="text" className='save-btn-aipath-table' onClick={() => { setDataShortestPath([]); setViewData(); setEditData() }}>Cancel</Button> :
                  <Button variant="text" className='cancel-btn-aipath-tables' onClick={() => {
                    setDataShortestPath([]); setEditData(); setViewData(); setEditData()
                    setViewData()
                    setsourceuid();
                    setFromalldata("")
                    settargetuid()
                    setToinput("")
                  }}>Cancel</Button>
              }
              {
                viewData ? null : editData ?
                  <Button variant="text" className='save-btn-aipath-table' onClick={() => setOpenNameModal(true)}>Update</Button> :
                  <Button variant="text" className='save-btn-aipath-table' onClick={() => setOpenNameModal(true)}>Save</Button>
              }
            </p>
          </div>

        </div>
      ) : null}
      <Modal
        open={openNameModal}
        onClose={() => setOpenNameModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="vrufyerv">
          <Box sx={style}>
            <div className="modal-head-heading-crossicon">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="enetr-name-heading-modal"
              >
                Enter Path name
              </Typography>
              <ClearIcon
                style={{ cursor: "pointer" }}
                onClick={() => setOpenNameModal(false)}
              />
            </div>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <label htmlFor="" className="search-name-label-modal">
              AI Path Name
              </label>
              <LabelInput
                value={pathName}
                onChange={(e) => setPathName(e?.target?.value)}
              />
            </Typography>
            <Stack>
              <div className="button-cancel-save-modal">
                <Button
                  variant="type"
                  className="cancel-button-modal"
                  onClick={() => {
                    setOpenNameModal(false); setPathName(""); setDataShortestPath(); setEditData()
                    setViewData()
                    setsourceuid();
                    setFromalldata("")
                    settargetuid()
                    setToinput("")
                  }}
                >
                  Cancel
                </Button>
                {
                  editData ? <Button
                    variant="type"
                    className={
                      pathName?.length > 1
                        ? "save-button-modal"
                        : "save-button-modalclass"}
                    onClick={handleUpdatePath}
                  >
                    Update
                  </Button> :
                    <Button
                      variant="type"
                      className={
                        pathName?.length > 1
                          ? "save-button-modal"
                          : "save-button-modalclass"}
                      onClick={handleSavePath}
                    >
                      Save
                    </Button>
                }
              </div>
            </Stack>
          </Box>
        </div>
      </Modal>

    </>
  );
}
