import * as React from "react";
import "./Style.css";
import SearchAiPath from "../../../Components/AiPath/SearchAiPath/Index";
import AllAiPaths from "../../../Components/AiPath/AllAiPaths/Index";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../../Components/Loader/Loader";
import { APIUrlOne, APIUrlThree, APIUrlToken, APIUrlTwo, GetUserId } from "../../../Utils/Utils";
import OrganizationSearch from "../../../Components/AiPath/OrganizationSearch/Index";
import SavedPath from "../../../Components/AiPath/SavedPath/SavedPath";
import Layout from "../../../Components/Layout/Layout";
import AiPathTable from "../AiPathTable/AiPathTable";
import CombinedPDFAiPath from "../../../Components/AiPath/CombinedPDFAiPath/CombinedPDFAiPath";
import html2pdf from "html2pdf.js";
import { Margin } from "react-to-pdf";
import { Button } from "@mui/material";
import video from "../../../Assets/video.svg"
import videodash from "../../../Assets/play-button.png"
import TrainingModal from "../../../Components/Training/TrainingModal";
import { AI_PATH_VIDEO, COMPETITIVE_INTEL_VIDEO, PERSONALIZED_DASHBOARD_VIDEO } from "../../../Utils/Constants";
export default function AIPath() {
  const [Fromalldata, setFromalldata] = React.useState("")
  const [ResponseData, setResponseData] = React.useState(null)

  const [ResponseDatanew, setResponseDatanew] = React.useState(null)
  const [Toinput, setToinput] = React.useState("")
  const [targetuid, settargetuid] = React.useState(null)
  const [sourceuid, setsourceuid] = React.useState(null);
  const [editData, setEditData] = React.useState();
  const [viewData, setViewData] = React.useState(false);
  const [dataShortestPath, setDataShortestPath] = React.useState("");
  const [dataShortestPath2, setDataShortestPath2] = React.useState([]);
  const [showAutocomplete, setShowAutocomplete] = React.useState(false);
  const [showAutocompletenew, setShowAutocompletenew] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [savedPaths, setSavedPaths] = React.useState([]);
  const [pathName, setPathName] = React.useState("")
  const [videoModal, setVideoModal] = React.useState(false);
  const [overviewVideoModal, setOverviewVideoModal] = React.useState(false);
  const [videoLink, setVideoLink] = React.useState("")
  const handleCloseOverview = () => {
    setOverviewVideoModal(false)
  }
  const handleVideoModal = (item) => {
    setOverviewVideoModal(true)
    if (item == 1) {
      setVideoLink(AI_PATH_VIDEO)
    } 
  }
  const handlesearch = () => {
    setLoading(true)
    const data = {
      people_name: Fromalldata
    };
    axios.post(`${APIUrlThree()}/v1/people_search`, data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        "X-API-Key": APIUrlToken()
      }
    })
      .then(response => {
        setLoading(false)
        if (response.status === 200) {
          setResponseData(response.data?.data);
          toast.success(response.data.message);
        }
      })
      .catch(error => {
        setLoading(false)
        toast.error(error.response.data.message);
      });
  };
  const handlesearchnew = () => {
    setLoading(true)
    const data = {
      people_name: Toinput
    };
    axios.post(`${APIUrlThree()}/v1/people_search`, data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        "X-API-Key": APIUrlToken()
      }
    })
      .then(response => {
        setLoading(false)
        if (response.status === 200) {
          setResponseDatanew(response.data?.data);
          toast.success(response.data.message);
        }
      })
      .catch(error => {
        setLoading(false)
        toast.error(error.response.data.message);
      });
  };
  const handelselectdata = (item) => {

    setShowAutocomplete(true)
    const people_name = `${item.first_name ? item.first_name : ''} ${item.last_name ? item.last_name : ''}${item.primary_job_title ? ', ' + item.primary_job_title : ''}${item.primary_organization ? ', ' + item.primary_organization + ' ' : ''}`; const id = `${item.person_id}`
    setsourceuid(id)
    setResponseData()
    setFromalldata(people_name);
    setShowAutocomplete(false)
  };
  const handelselectdatanew = (item) => {

    setShowAutocompletenew(true)
    const people_name = `${item.first_name ? item.first_name : ''} ${item.last_name ? item.last_name : ''}${item.primary_job_title ? ', ' + item.primary_job_title : ''}${item.primary_organization ? ', ' + item.primary_organization + ' ' : ''}`; const id = `${item.person_id}`
    settargetuid(id)
    setResponseDatanew()
    setToinput(people_name);
    setShowAutocompletenew(false)
  };
  const validation = () => {
    if (Fromalldata === "") {
      toast.error("Please Select From")
      return false
    }
    if (Toinput === "") {
      toast.error("Please Select to")
      return false
    }
    return true
  }
  const handleRightsidebar = () => {
    if (!validation()) return
    setLoading(true)
    const data = {};
    data.source_uid = Number(sourceuid)
    data.target_uid = Number(targetuid)
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      data: data,
      url: `${APIUrlTwo()}/v1/shortest-path`,
    };
    axios(option)
      .then((response) => {
        setLoading(false);
        if (response?.status === 200) {
          // setFromalldata("")
          // setToinput("")
          const data = Object.values(response?.data)
          if (data?.[0]?.message === "No Path Found") {
            toast.error("No path found.");
            setDataShortestPath([])
            return
          }
          setDataShortestPath(data);
        }
      })
      .catch((err) => {
        setLoading(false)
        toast.error(err?.response?.data?.message || "No path found");
      });
  };
  React.useEffect(() => {
    let timer
    if (Toinput.length > 2) {
      timer = setTimeout(() => {
        handlesearchnew();
      }, 1000);
    }
    return () => clearTimeout(timer)
  }, [Toinput]);
  React.useEffect(() => {
    let timer
    if (Fromalldata.length > 2) {
      timer = setTimeout(() => {
        handlesearch();
      }, 1000);
    }
    return () => clearTimeout(timer)
  }, [Fromalldata]);

  const getSavedAiPath = () => {
    setLoading(true)
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/get_aipath?userId=${GetUserId()}`,
    };
    axios(option)
      .then((response) => {
        setLoading(false);
        setSavedPaths(response?.data?.data);


      })
      .catch((err) => {
        setLoading(false)
        toast.error(err?.response?.data?.message);
      });

  };
  React.useEffect(() => {
    getSavedAiPath()
  }, [])
  const downloadPath = (item) => {
    let datajson = item?.json_result || []
    const data = (datajson)
    setDataShortestPath2({ source_id: item?.source_id, destination_id: item?.destination_id, data: data })
  }
  React.useEffect(() => {
    if (dataShortestPath2?.source_id && dataShortestPath2?.destination_id) {
      handleToPDFDownload()
    }
  }, [dataShortestPath2])
  const contentRef = React.useRef(null);
  const handleToPDFDownload = () => {
    const content = contentRef.current;
    const options = {
      filename: 'aiPath.pdf',
      margin: 10,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: {
        format: 'letter',
        orientation: 'landscape',
      },
    };
    html2pdf().set(options).from(content).save();
    setDataShortestPath2([])
  };

  const deleteSavedAiPath = (item) => {
    setLoading(true)
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/delete_aipath?aipathId=${item?.id}`,
    };
    axios(option)
      .then((response) => {
        setLoading(false);
        getSavedAiPath();
      })
      .catch((err) => {
        setLoading(false)
        toast.error(err?.response?.data?.message);
      });

  };
  const editAiPath = (item) => {
    let datajson = item?.json_result || []
    const data = (datajson)
    setDataShortestPath(data)
    setEditData(item)
    let fromname = data?.[0]?.bestPathInfor?.nodes?.find(i => i?.uid == data?.[0]?.source.toString())
    let toname = data?.[0]?.bestPathInfor?.nodes?.find(i => i?.uid == data?.[0]?.target.toString())
    const people_fromname = `${fromname?.name ? fromname?.name : ''}${fromname?.company ? ', ' + fromname?.company + ' ' : ''}`;
    const people_toname = `${toname?.name ? toname?.name : ''}${toname?.company ? ', ' + toname?.company + ' ' : ''}`;
    setsourceuid(data?.[0]?.source)
    setFromalldata(people_fromname);
    settargetuid(data?.[0]?.target)
    setToinput(people_toname);
    setPathName(item?.path_name)
  }
  const viewAiPath = (item) => {
    let datajson = item?.json_result || []
    const data = (datajson)
    setDataShortestPath(data)
    setViewData(data)
  }
  return (
    <>
      <Layout>
        {loading ? <Loader /> : null}
        <div className="child-section-of-everypage-aipath">
          {
            viewData ? null :
              <div className="video-space_inner">
                <SearchAiPath
                  setShowAutocomplete={setShowAutocomplete}
                  showAutocompletenew={showAutocompletenew}
                  setShowAutocompletenew={setShowAutocompletenew}
                  showAutocomplete={showAutocomplete}
                  handelselectdata={handelselectdata}
                  Fromalldata={Fromalldata}
                  setFromalldata={setFromalldata}
                  handlesearch={handlesearch}
                  ResponseData={ResponseData}
                  setResponseData={setResponseData}
                  Toinput={Toinput}
                  setToinput={setToinput}
                  handelselectdatanew={handelselectdatanew}
                  handlesearchnew={handlesearchnew}
                  handleRightsidebar={handleRightsidebar}
                  ResponseDatanew={ResponseDatanew}
                  setsourceuid={setsourceuid}
                />
                <div className="video_container" onClick={() => setVideoModal(!videoModal)}>

                  <img src={video} alt="video" className="videoIcon" />
                  {videoModal && (
                    <div className="bell-icon-box-video">

                      <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(1)}>
                        <div className="bell-icon-outer-video">
                          {/* <div className="bell-icon-container-inner"> */}
                          <img src={videodash} alt="logo" className="video_img_drop" />
                          {/* </div> */}
                          <div className="bellicon-text">
                            <p className="bellicon-p-search">AI Path</p>
                          </div>
                        </div>
                        <div className="bellicon-separator"></div>
                      </div>
                      

                    </div>
                  )}
                </div>
              </div>
          }
          {/* <div> <OrganizationSearch /> </div> */}
          {
            dataShortestPath?.length ?
              <div>
                <AllAiPaths
                  dataShortestPath={dataShortestPath}
                  setDataShortestPath={setDataShortestPath}
                  sourceuid={sourceuid}
                  targetuid={targetuid}
                  getSavedAiPath={getSavedAiPath}
                  pathName={pathName}
                  setPathName={setPathName}
                  editData={editData}
                  viewData={viewData}
                  setViewData={setViewData}
                  setEditData={setEditData}
                  setsourceuid={setsourceuid}
                  setFromalldata={setFromalldata}
                  settargetuid={settargetuid}
                  setToinput={setToinput} />
              </div> : editData ?
                <div className="apiath-noresult">
                  <h3>No Path found</h3>
                  <p className='ai-path-table-btn'>
                    <Button variant="text" className='save-btn-aipath-table' onClick={() => {
                      setDataShortestPath([]); setViewData(); setEditData(); setEditData()
                      setViewData()
                      setsourceuid();
                      setFromalldata("")
                      settargetuid()
                      setToinput("")
                    }}>Cancel</Button>


                  </p>
                </div> : null
          }
          {
            dataShortestPath2 ?
              <div style={{ display: 'none' }}>
                <div id="content-id" ref={contentRef}>
                  <CombinedPDFAiPath style={{ width: "100%" }}
                    dataShortestPath={dataShortestPath2?.data}
                    setDataShortestPath={setDataShortestPath2}
                    sourceuid={dataShortestPath2?.source_id}
                    targetuid={dataShortestPath2?.destination_id} />
                </div>
              </div> : null
          }
          <SavedPath savedPaths={savedPaths} downloadPath={downloadPath} deleteSavedAiPath={deleteSavedAiPath} editAiPath={editAiPath} viewAiPath={viewAiPath} />
          {/* <AiPathTable /> */}
        </div>
      </Layout>
      <TrainingModal overviewVideoModal={overviewVideoModal} handleCloseOverview={handleCloseOverview} videoLink={videoLink} />
    </>
  );
}