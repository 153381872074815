import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout/Layout";
import "./Style.css";
import AiLeadsAction from "../../Components/AiLeads/AiLeadsButton/AiLeadsAction";
import DecisionMakerTable from "../../Components/DecisionMaker/DecisionMakerTable";
import DecisionmakersFilter from "../../Components/DecisionMaker/DecisionFilter/Index";
import { toast } from "react-toastify";
import axios from "axios";
import { APIUrlOne, APIUrlThree, APIUrlToken, GetUserId } from "../../Utils/Utils";
import Loader from "../../Components/Loader/Loader";
const DecisionMaker = () => {
  const userId = GetUserId();
  const [loading, setLoading] = useState(false);
  const [tableCommingData, setTableCommingData] = React.useState([]);
  const [AiProspectData, setAiProspectData] = useState([])
  const [firstFilterData, setFirstFilterData] = useState([]);
  const [istableDataFilter, setIstableDataFilter] = React.useState(false);
  const [currentLeadsLength, setCurrentLeadsLength] = React.useState('');
  const [isSalesForceTrigger, setIsSalesForceTrigger] = useState(false);
  const [isDecisionMakerExcel, setIsDecisionMakerExcel] = useState(false);
  const [selectedData, setSelectedData] = React.useState([]);
  const [showData, setShowData] = React.useState([]);
  const [lastdata, setlastdata] = React.useState([]);  
  const [selectedSeniorityData, setSelectedSeniorityData] = React.useState([]);
  const [skip, setSkip] = React.useState(0);
  const [decisionMakerData, setDecisionMakerData] = React.useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [statsCountDecisionMaker, setStatsCountDecisionMaker] = useState(0);
  const [applyFilter, setIsApplyFilter] = useState(false);
  const [archiveRecord, setArchiveRecord] = useState(false);
  const [selectedCountries, setSelectedCountries] = React.useState([]);
  const [trackRecord, setTrackRecord] = useState(false)
  const [requestUpdateRecord, setRequestUpdateRecord] = useState(false);
  const [disableRequestUpdate, setDisableRequestUpdate] = useState(false);
  const [profileRecords, setProfileRecords] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState();
  const perPage = 50;
  const [showSalesForce,setShowSalesForce]=useState("false")

  const validateFilters = () => {
    if (!selectedData?.length && !showData?.length && !lastdata?.length && !selectedCountries?.length && !selectedCountries?.length && !selectedSeniorityData?.length 
    ) {
      toast.error("Please Select Filters");
      return false;
    }
    return true;
  }
  const duplicateHandlePass = (e) => {
    if (!validateFilters()) return;
  
    setLoading(true);
    setIsApplyFilter(true);
    setSkip(0);
  
    const data = {};
    data.categories = selectedData;
    data.decision_maker = showData?.[0] === "Yes" ? true : showData?.[0] === "No" ? false : null
    data.josf_status =lastdata?.[0] === "Available" ? true : lastdata?.[0] === "Not Available" ?false : null;
    
  
    const formattedSeniority = selectedSeniorityData.includes('C-Level')
      ? Array.from(new Set([...selectedSeniorityData, 'C Level']))
      : selectedSeniorityData;
  
    data.seniority = formattedSeniority;
    data.country = selectedCountries;
  
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken(),
      },
      url: `${APIUrlThree()}/v1/apply_people_filter?limit=50&skip=0`,
      data: JSON.stringify(data),
    };
  
    axios(option)
      .then((e) => {
        setTimeout(() => setLoading(false), 100);
        if (e?.status === 200) {
          const comingData = e?.data?.data?.sort((a, b) => (b.decision_maker ? 1 : 0) - (a.decision_maker ? 1 : 0));
          const statsCount = e?.data?.count;
          setStatsCountDecisionMaker(statsCount);
          setFirstFilterData(comingData);
  
          if (comingData.length === 0 || comingData.length % 50 !== 0) {
            setHasMore(false);
          } else {
            setTimeout(() => setHasMore(true), 1000);
          }
  
          setTableCommingData(comingData);
          toast.success(e?.data?.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };
  
  const handlePassSubmit = () => {
    // if (!validateFilters()) return;
    // if (statsCountDecisionMaker <= tableCommingData.length) return;
    setHasMore(false);
    setLoading(true);
    const data = {};
    data.categories = selectedData;
    // data.decision_maker = showData?.[0];
    // data.josf_status = lastdata?.[0];
    data.seniority = selectedSeniorityData;
    data.country = selectedCountries;
    data.decision_maker = showData?.[0]?.decisionmaker === "Yes" ? true : showData?.[0]?.decisionmaker === "No" ? false : null
    data.josf_status =lastdata?.[0]?.josfStatus === "Available" ? true : lastdata?.[0]?.josfStatus === "Not Available" ?false : null;
    
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/apply_people_filter?limit=${perPage}&skip=${skip ? skip * 50 : 0}`,
      data: JSON.stringify(data),
    };
    axios(option)
      .then((e) => {
        setTimeout(() => setLoading(false), 100)
        if (e?.status === 200) {
          const comingData = e?.data?.data?.sort((a, b) => (b.decision_maker ? 1 : 0) - (a.decision_maker ? 1 : 0));
          const statsCount = e?.data?.count;
          setStatsCountDecisionMaker(statsCount);
          if (comingData.length === 0 || comingData.length % 50 !== 0) {
            setHasMore(false);
          } else {
            setTimeout(() => {
              setHasMore(true);
            }, 1000);
          }
          if (skip > 0) {
            setTableCommingData(prevdata => [...prevdata, ...comingData,])
          } else {
            setTableCommingData(comingData);
          }
          toast.success(e?.data?.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };
  const decisionMakerProfile = () => {
    setLoading(true);
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/get_filter_people?user_id=${userId}`,
    };
    axios(option)
      .then((e) => {
        setLoading(false);
        if (e?.status === 200) {
          setAiProspectData(e?.data?.data);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }
  useEffect(() => {
    decisionMakerProfile()
  }, [])
  const handleApplyProfile=(item)=>{
    setSkip(0)
    setSelectedProfile(item)
    setProfileRecords(true)
  }  
  const DecisionMakerDataListing = () => {
    setLoading(true);
    const data = {};
    data.user_id = GetUserId();
    data.name = selectedProfile?.addedName;
    data.decision_maker =
      selectedProfile?.decisionmaker === "True" ? true : selectedProfile?.decisionmaker === "False"? false : selectedProfile?.decisionmaker;
    data.primary_organization = selectedProfile?.primaryOrganization;
    data.categories =(selectedProfile?.categories);
    data.number_employees = (selectedProfile?.number_employees) || null;
    data.revenue_range = (selectedProfile?.revenue_range) || null;
    data.country = (selectedProfile?.country);
    data.state = selectedProfile?.state || null;
    data.josf_status = (selectedProfile?.josf_status === "True" ||selectedProfile?.josf_status === "true") ? true : (selectedProfile?.josf_status === "False" || selectedProfile?.josf_status === "false")?false : null;
    data.j_score = selectedProfile?.strengthScore;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/apply_people_filter?limit=${perPage}&skip=${skip ? skip * 50 : 0}`,
      data: JSON.stringify(data),
    };
    axios(option)
      .then((e) => {
        setTimeout(() => setLoading(false), 100)
        if (e?.status === 200) {
          const comingData = e?.data?.data?.sort((a, b) => (b.decision_maker ? 1 : 0) - (a.decision_maker ? 1 : 0));
          const statsCount = e?.data?.count;
          setStatsCountDecisionMaker(statsCount);
          if (comingData.length === 0 || comingData.length % 50 !== 0) {
            setHasMore(false);
            setProfileRecords(false)
          } else {
            setTimeout(() => {
              setProfileRecords(true)
              setHasMore(true);
            }, 1000);
          }
          if (skip > 0) {
            setTableCommingData(prevdata => [...prevdata, ...comingData,])
          } else {
            setTableCommingData(comingData);
          }
          toast.success(e?.data?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };
  return (
    <>
          {loading ? <Loader /> : null}
      <Layout>
        <div className="child-section-of-everypage">
          <AiLeadsAction
            currentLeadsLength={currentLeadsLength}
            setIsSalesForceTrigger={setIsSalesForceTrigger}
            isSalesForceTrigger={isSalesForceTrigger}
            setIsDecisionMakerExcel={setIsDecisionMakerExcel}
            statsCountDecisionMaker={statsCountDecisionMaker}
            setArchiveRecord={setArchiveRecord}
            setTrackRecord={setTrackRecord}
            setRequestUpdateRecord={setRequestUpdateRecord}
            disableRequestUpdate={disableRequestUpdate}
            showSalesForce={showSalesForce}
            />
          <DecisionmakersFilter
            tableCommingData={tableCommingData}
            setTableCommingData={setTableCommingData}
            istableDataFilter={istableDataFilter}
            setIstableDataFilter={setIstableDataFilter}
            duplicateHandlePass={duplicateHandlePass}
            setSelectedData={setSelectedData}
            selectedData={selectedData}
            setShowData={setShowData}
            showData={showData}
            setlastdata={setlastdata}
            lastdata={lastdata}
            setStatsCountDecisionMaker={setStatsCountDecisionMaker}
            AiProspectData={AiProspectData}
            selectedSeniorityData={selectedSeniorityData}
            setSelectedSeniorityData={setSelectedSeniorityData}
            selectedCountries={selectedCountries}
            setSelectedCountries={setSelectedCountries}
            handleApplyProfile={handleApplyProfile}
            setSkip={setSkip}
            setProfileRecords={setProfileRecords}
            setSelectedProfile={setSelectedProfile}
          />
          <DecisionMakerTable
            tableCommingData={tableCommingData}
            setTableCommingData={setTableCommingData}
            istableDataFilter={istableDataFilter}
            setCurrentLeadsLength={setCurrentLeadsLength}
            isSalesForceTrigger={isSalesForceTrigger}
            setIsSalesForceTrigger={setIsSalesForceTrigger}
            isDecisionMakerExcel={isDecisionMakerExcel}
            setIsDecisionMakerExcel={setIsDecisionMakerExcel}
            setIstableDataFilter={setIstableDataFilter}
            handlePassSubmit={handlePassSubmit}
            selectedData={selectedData}
            setSkip={setSkip}
            skip={skip}
            setDecisionMakerData={setDecisionMakerData}
            decisionMakerData={decisionMakerData}
            setHasMore={setHasMore}
            hasMore={hasMore}
            firstFilterData={firstFilterData}
            setIsApplyFilter={setIsApplyFilter}
            applyFilter={applyFilter}
            archiveRecord={archiveRecord}
            setArchiveRecord={setArchiveRecord}
            trackRecord={trackRecord}
            setTrackRecord={setTrackRecord}
            requestUpdateRecord={requestUpdateRecord}
            setRequestUpdateRecord={setRequestUpdateRecord}
            setDisableRequestUpdate={setDisableRequestUpdate}
            setProfileRecords={setProfileRecords}
            profileRecords={profileRecords}
            DecisionMakerDataListing={DecisionMakerDataListing}
            setShowSalesForce={setShowSalesForce}
          />
        </div>
      </Layout>
    </>
  );
};
export default DecisionMaker;