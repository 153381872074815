import * as React from "react";
import PropTypes from "prop-types";
import "../DecisionMaker/DecisionMakerTable.css";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import decisionMakerImage from "../../Assets/Cloudfigma.svg";
import ContactDetailList from "../../Components/AiLeads/ContactDetailList/ContactDetailList";
import RightSidebar from "../RightSiderbar/RightSiderbar";
import axios from "axios";
import Loader from "../Loader/Loader";
import { APIUrlOne, APIUrlThree, APIUrlToken, APIUrlTwo, GetOktaAuthData, GetUserId } from "../../Utils/Utils";
import IndustryDropdown from "../AiLeads/IndustrySectorDropdown/Index";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import * as XLSX from 'xlsx';
import starRed from '../../Assets/starRed.svg'
import startgray from '../../Assets/Stargrey.png'
import { useNavigate } from "react-router-dom";
import ProspectRequestModal from "../RequestUpdateModals/ProspectRequestModal";
import MessageModal from "../MessageModal/MessageModal";
function Row({ row, connectionStrength, setLoading, setRowsSelect, selectedOuterRows, favoritesList, handleRowSelect, listFavoriteRecords }) {
  const [openSidebar, setOpenSidebar] = React.useState(false);
  const [dataShortestPath, setDataShortestPath] = React.useState();
  const loggedInUserId = GetUserId();
  const [userDetails, setUserDeatils] = React.useState();
  const navigate = useNavigate()
  const handleRightsidebar = (event) => {
    const data = {};
    data.source_uid = Number(loggedInUserId);
    data.target_uid = Number(event?.person_id);
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      data: data,
      url: `${APIUrlTwo()}/v1/shortest-path`,
    };
    axios(option)
      .then((response) => {
        if (response?.status === 200) {
          const data = Object.values(response.data);
          setDataShortestPath(data);
          setOpenSidebar(true);
          setUserDeatils(event)
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const handleFavouriteLead = (row) => {
    const data = {
      "user_id": Number(GetUserId()),
      "people_id": row.person_id
    }
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      data: data,
      url: `${APIUrlThree()}/v1/save_favourite_prospect`,
    };
    axios(option)
      .then(async (response) => {
        setLoading(false);
        listFavoriteRecords()
        toast.success("Prospect added to favorites")
      })
      .catch(() => {
        setLoading(false);
      });
  }
  const handleRemoveFavouriteLead = (row) => {
    const data = favoritesList?.find(item => item?.person_id == row?.person_id)
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/remove_favourite_prospect?id=${data?.favourites_id}`,
    };
    axios(option)
      .then(async (response) => {
        setLoading(false);
        listFavoriteRecords()
        toast.success("Prospect removed from favorites")
      })
      .catch(() => {
        setLoading(false);
      });
  }

  return (
    <TableRow className="juyds" sx={{ "& > *": { borderBottom: "unset" } }}>
      <>
        <TableCell className="checkbox-row-table-inner checkbox-row-table">
          <div className="kjhgf">
            <input
              type="checkbox"
              onChange={() => handleRowSelect(row)}
              checked={selectedOuterRows.some(item => item.person_id === row?.person_id)}
            />
          </div>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
        >
          <>
            {
              favoritesList?.some(item => item?.person_id == row?.person_id) ?
                <div className="kjhgf" onClick={() => handleRemoveFavouriteLead(row)}>
                  <img src={starRed} alt="" />
                </div> :
                <div className="kjhgf" onClick={() => handleFavouriteLead(row)}>
                  <img src={startgray} alt="" />
                </div>
            }
          </>
        </TableCell>
        <TableCell className="Decisions-row-tableName-prospect" align="left">

          <div
            className={
              row?.decision_maker === true
                ? "Decision-maker-user-name"
                : "Decision-maker-user-noborder create-name-img"
            }
          >
            <img
              className={
                row?.suspect_status === null ? "hide-image" : "decisionMakerImageAi-Decisionmaker"
              }
              src={decisionMakerImage}
              alt=""
            />
            <p className="letter-heading">{row?.first_name ? row?.first_name?.split(" ")?.find(item => !item.includes('('))?.charAt(0) : ""}{row?.last_name ? row?.last_name?.split(" ")?.find(item => !item.includes('('))?.charAt(0) : ""}</p>
          </div>
        </TableCell>
        <TableCell className="Decision-maker-userTeblesell_row" align="left" onClick={() => { navigate(`/Profile/${row?.person_id == 0 ? "0" : row?.person_id}`) }} style={{ cursor: "pointer" }}>          <div className="Decision-maker-user-name-main-container">

          <div className="name-and-title-text">
            <div className="fullnameofuser">
              <h3 className="company-name-country">{row?.first_name ? row?.first_name : "-"} {row?.last_name ? row?.last_name : "-"}</h3>
            </div>
            <p className="after-company-name-country-fordecisionmaker">
              {
                row?.primary_job_title
                  ? row.primary_job_title.substring(0, 18) +
                  (row.primary_job_title.length > 18 ? "..." : "")
                  : "-"
              }
            </p>
          </div>

        </div>
        </TableCell>
        <TableCell component="th" scope="row">
          <p className="joi-strength-control">{row?.strengthData?.strength ? row?.strengthData?.strength : "-"}</p>
        </TableCell>
        <TableCell align="left">
          <h3 className="annual-revenue-table">{row.primary_organization ? row.primary_organization : "-"}</h3>
        </TableCell>
        <TableCell
          align="left"
          className="table-cell-of-contact-details-dropdown-th"
        >
          <div
            className="Set-dropdown-ofIndustry"
            style={{ position: "relative" }}
          >
            <div className="email-andrelative-other-info">
              <div className="maked-component-of-dropdown-forai-leads">
                <IndustryDropdown row={row} />
              </div>
            </div>
          </div>
        </TableCell>
        <TableCell align="left">
          <div className="Suspect-table-data">
            <h3 className="industry-sector-table">
              {row?.suspect_status ? row.suspect_status : "-"}
            </h3>
          </div>
        </TableCell>
        <TableCell align="left" className="table-cell-of-contact-details-dropdown-th-prospect">
          <div
            className="Set-dropdown-ofContactDetailList"
            style={{ position: "relative" }}
          >
            <div className="email-and-other-infodc">
              <div className="maked-component-of-dropdown">
                <ContactDetailList item={row} />
              </div>
            </div>
          </div>
        </TableCell>
      </>
      <TableCell className="table-cellhandleRightsidebar-prospect">
        <div className="table-cellhandleRightsidebar" style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            className="button-collapse-table-propect"
          >
            <RightSidebar
              dataShortestPath={dataShortestPath}
              openSidebar={openSidebar}
              userDetails={userDetails}
              handleRightsidebar={handleRightsidebar}
              rowid={row}
            />
          </IconButton>
        </div>
      </TableCell>
    </TableRow>
  );
}
Row.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  connectionStrength: PropTypes.string.isRequired,
};
export default function DecisionMakerTable({
  tableCommingData,
  setTableCommingData,
  istableDataFilter,
  setCurrentLeadsLength,
  isDecisionMakerExcel,
  setIsDecisionMakerExcel,
  setIstableDataFilter,
  isSalesForceTrigger, setIsSalesForceTrigger,
  handlePassSubmit,
  firstFilterData,
  setSkip,
  skip,
  setIsApplyFilter,
  applyFilter,
  archiveRecord, setArchiveRecord,
  trackRecord, setTrackRecord,
  requestUpdateRecord, setRequestUpdateRecord, setDisableRequestUpdate,
  setProfileRecords,
  profileRecords,
  DecisionMakerDataListing,setShowSalesForce }) {
    const userInfo = GetOktaAuthData();
  const loggedUser = userInfo?.user?.profile;
  const exportToExcel = (data, filename) => {
    const filteredData = data.map(({ person_id, org_id, strengthData, ...rest }) => rest);
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${filename}.xlsx`);
    setIsDecisionMakerExcel(false);
  };
  const [loading, setLoading] = React.useState(false);
  const [decisionMakerData, setDecisionMakerData] = React.useState([]);
  const [checkDecisionMakerData, setcheckDecisionMakerData] = React.useState([]);
  const loggedInUserId = GetUserId();
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [hasMore, setHasMore] = React.useState(false);
  const [isfetchData, setIsfetchData] = React.useState(false);
  const [totalPages, setTotalPages] = React.useState(10);
  const [totalCount, setTotalCount] = React.useState('');
  const [rowsSelect, setRowsSelect] = React.useState({});
  const [selectedOuterRows, setSelectedOuterRows] = React.useState([])
  const [favoritesList, setFavoritesList] = React.useState([])
  const [archivesList, setArchivesList] = React.useState([])
  const [requestUpdateModal, setRequestUpdateModal] = React.useState(false)
  const [messageModal, setMessageModal] = React.useState(false)
  const [messageModalTrack, setMessageModalTrack] = React.useState(false)
  const [messageModalSalesforce, setMessageModalSalesforce] = React.useState(false)

  const perPage = 50
  React.useEffect(() => {
    if (tableCommingData) {
      setDecisionMakerData(tableCommingData);
    }
  }, [tableCommingData])

  const fetchDataReturnFilter = () => {
    setLoading(true);
    const option = {
      method: "GET",
      headers: {
        "content-type": "plain/text",
        "X-API-Key": APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/people?userid=${GetUserId()}&limit=50&skip=${skip ? skip*50 : 0}`,
    };
    axios(option)
      .then((response) => {
        setLoading(false);
        const comingData = response?.data?.data?.sort((a, b) => (b.decision_maker ? 1 : 0) - (a.decision_maker ? 1 : 0));
        if (comingData.length === 0) {
          setHasMore(false);
        } else {
          setDecisionMakerData(comingData);
          setTableCommingData(comingData);
          setIstableDataFilter(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const getStrength = async () => {
    // if (!tableCommingData?.length) return;
    // setLoading(true);
    const tuples = tableCommingData.map(item => ({
      items: [Number(GetUserId()), item.person_id]
    }));
    const data = { tuples };
    const options = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      data: data,
      url: `${APIUrlTwo()}/v1/conn_strength`,
    };
    try {
      const response = await axios(options);
      const strengthData = response?.data
      const updatedData = tableCommingData?.map((item, index) => ({
        ...item,
        strengthData: strengthData[index]
      }));
      setDecisionMakerData(updatedData);
    } catch (error) {
      // setLoading(false);
      toast.error(error?.response?.data?.message);
    } finally {
      // setLoading(false);
    }
  }
  React.useEffect(() => {
    if (checkDecisionMakerData || tableCommingData) {
      getStrength();
    }
  }, [checkDecisionMakerData, tableCommingData]);
  React.useEffect(() => {
    if(profileRecords===true){
      DecisionMakerDataListing()
    }
    else if (firstFilterData?.length && skip > 0) {
      handlePassSubmit();
    }
    else if (firstFilterData?.length === 0 && !applyFilter) {
      handlePassSubmit()
      // if (isfetchData) {
      //   // fetchData();
      // } else {
      //   setIsfetchData(true);
      // }
    }
  }, [skip, firstFilterData, applyFilter, isfetchData,profileRecords]);
  React.useEffect(()=>{
    // fetchData()
  },[])
  React.useEffect(() => {
    if (istableDataFilter) {
      fetchDataReturnFilter();
    }
  }, [istableDataFilter]);
  React.useEffect(() => {
    if (decisionMakerData) {
      setCurrentLeadsLength(decisionMakerData?.length);
      // const favorite = decisionMakerData?.filter(item => item?.favourite_id !==null)?.map(item => item?.id)
      // setFavoritesList(favorite)
    }
  }, [decisionMakerData])
  React.useEffect(() => {
    if (isDecisionMakerExcel) {
      if(!selectedOuterRows?.length){
        toast.error("Please select records to download");
        setIsDecisionMakerExcel(false);
        return
      }
      exportToExcel(selectedOuterRows, 'decisionmaker_exported_data');
    }
  }, [isDecisionMakerExcel])

  const loadMore = () => {
    setLoading(true);
    setSkip(prev => prev + 1);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };
  const handleRowSelect = (row) => {
    setSelectedOuterRows(prevSelectedRows => {
      const isAlreadySelected = prevSelectedRows.some(item => item.person_id === row.person_id);

      if (isAlreadySelected) {
        return prevSelectedRows.filter(item => item.person_id !== row.person_id);
      } else {
        return [...prevSelectedRows, row];
      }
    });

  };
  React.useEffect(() => {
    if (selectedOuterRows?.length < 1 && requestUpdateRecord == true) {
      setDisableRequestUpdate(false)
      setRequestUpdateRecord(false)
      toast.error("Please select record to request update")
      return
    } else if (selectedOuterRows?.length === 1 && requestUpdateRecord == true) {
      setRequestUpdateModal(true)
      setRequestUpdateRecord(false)
      setDisableRequestUpdate(false)
    } else if (selectedOuterRows?.length > 1) {
      setDisableRequestUpdate(true)
      setRequestUpdateRecord(false)
    } else {
      setDisableRequestUpdate(false)
    }
  }, [selectedOuterRows, requestUpdateRecord])

  const handleTrackRecords = (item) => {
    if (!selectedOuterRows?.length) {
      toast.error("Please select records to track")
      setTrackRecord(false)
      return
    } else {
      setLoading(true);
      const data = selectedOuterRows.map(item => item?.person_id);
      const option = {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          "X-API-Key": APIUrlToken()
        },
        data: data,
        url: `${APIUrlThree()}/v1/save_tracking_prospect?user_id=${Number(GetUserId())}`,
      };
      axios(option)
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            setTrackRecord(false)
            toast.success("Tracking Added")
            setSelectedOuterRows([])
            setMessageModalTrack(false)
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response.data.message);
        });
    }
  }

  React.useEffect(() => {
    if (trackRecord == true) {
      if (!selectedOuterRows?.length) {
        toast.error("Please select records to track")
        setTrackRecord(false)
        return
      }
      setMessageModalTrack(true)
      setTrackRecord(false)

      // handleTrackRecords()
    }
  }, [trackRecord])
  React.useEffect(() => {
    if (archiveRecord == true) {
      if (!selectedOuterRows?.length) {
        toast.error("Please select records to archive")
        setArchiveRecord(false)
        return
      }
      setMessageModal(true)
      setArchiveRecord(false)
    }
  }, [archiveRecord])
  const listFavoriteRecords = async () => {
    const options = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/list_favourite_prospects?userid=${Number(GetUserId())}&limit=200&offset=0`,
    };
    try {
      const response = await axios(options);
      setFavoritesList(response?.data?.data)
      // setArchiveRecord(false)
      // toast.success("Prospects Archived Successfully")
    } catch (error) {
      // setLoading(false);
      toast.error(error?.response?.data?.message);
    } finally {
      // setLoading(false);
    }
  }
  const listArchiveRecords = async () => {
    const options = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/list_user_archive_prospects?userid=${Number(GetUserId())}&limit=200&offset=0`,
    };
    try {
      const response = await axios(options);
      const data = response?.data?.data?.filter(item => item?.org_id !== null)
      setArchivesList(data)
    } catch (error) {
      // setLoading(false);
      toast.error(error?.response?.data?.message);
    } finally {
      // setLoading(false);
    }
  }
  React.useEffect(() => {
    listArchiveRecords()
    listFavoriteRecords()
  }, [])
  const handleArchiveRecords = async () => {
    if (!selectedOuterRows?.length) {
      toast.error("Please select records to archive")
      setArchiveRecord(false)
      return
    } else {
      const data = selectedOuterRows.map(item => ({
        user_id: Number(GetUserId()),
        // leads_id: null,
        people_id: item?.person_id
      }));

      const options = {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          "content-type": "application/json",
          "X-API-Key": APIUrlToken()
        },
        data: data,
        url: `${APIUrlThree()}/v1/send_archive`,
      };
      try {
        const response = await axios(options);
        setMessageModal(false)
        setArchiveRecord(false)
        if(response?.data?.message==="Already archived"){
          toast.success("Prospects already Archived.")
          
        }else{
          
          toast.success("Prospects Archived Successfully")
        }
        setSelectedOuterRows([])
        listArchiveRecords()
      } catch (error) {
        // setLoading(false);
        toast.error(error?.response?.data?.message);
      } finally {
        // setLoading(false);
      }
    }
  }
  const validateSalesforce = () => {
    if (!selectedOuterRows || selectedOuterRows?.length === 0) {
      toast.error("Please Select Records For Push To Salesforce");
      return false;
    }
    return true;
  };
  const pushToSalesForce = () => {
    if (!validateSalesforce()) return;
    setLoading(true);

    const ms = {
      updateData: {
        data: selectedOuterRows?.map((item) => ({
          items: [item?.person_id, "Suspect"],
        })),
      },
    };
    const transformedData = selectedOuterRows
      ?.map((i) => {
        const organizationInfo =
          decisionMakerData.find((org) => org?.org_id === i?.org_id) || {};
        return { ...i, organizationInfo };
      })
      ?.map((item) => ({
        attributes: {
          type: "Lead",
          referenceId: "rec" + item?.person_id,
        },
        LastName: (item?.last_name),
        FirstName: (item?.first_name),
        Salutation: null,
        Email: item?.email,
        Phone: item?.phone_no,
        Title: item?.primary_job_title,
        Company: item?.primary_organization,
        City: item?.organizationInfo?.location_identifiers?.split(",")[0],
        State: item?.organizationInfo?.location_identifiers?.split(",")[1],
        Country: item?.organizationInfo?.country,
        LeadSource: "JOI",
        Status: "Suspect",
        LinkedIn_Profile__c: item?.linkedin,
        Suspect_Source_Detail__c: `${loggedUser?.firstName} ${loggedUser?.lastName}`,
      }));
    const finalJson = { records: transformedData, ...ms };
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      data: finalJson,
      url: `${APIUrlOne()}/v1/add_suspects`,
    };
    axios(option)
      .then(async (response) => {
        setLoading(false);
        if (response?.status === 200) {
          toast.success("Record has been created");
          // const promiseRequest = selectedRows?.map((item) => {
          //   return ExpandHandler(item?.org_id, 1);
          // });
          // const result = await Promise.all(promiseRequest);
          // setExpandHandlerAccept(...result);
          setSelectedOuterRows([]);
          setMessageModalSalesforce(false)
          setIsSalesForceTrigger(false)
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message || "An Error occurred");
      });
  };

  React.useEffect(() => {
    if (isSalesForceTrigger) {
      if (!validateSalesforce()) return;
      setMessageModalSalesforce(true)
      // pushToSalesForce();
    }
  }, [isSalesForceTrigger]);

  React.useEffect(() => {
    if (selectedOuterRows?.length) {
      const data = selectedOuterRows?.filter((item) => item.suspect_status !== null)
      if (data?.length) {

        setShowSalesForce("disable")
      } else {
        setShowSalesForce("show")
      }
    }else{
      setShowSalesForce(false)
    }
  }, [selectedOuterRows])
  return (
    <>
      {loading ? <Loader /> : null}
      {/* <InfiniteScroll
        dataLength={tableCommingData.length}
        next={fetchMoreData}
        hasMore={hasMore}
        scrollableTarget="DecisionMaker-table-main"
      > */}
      <TableContainer component={Paper} className="ai-leads-table-main">
        <Table aria-label="collapsible table" className="ai-leads-table">
          <TableHead>
            <TableRow className="table-row-ai-leads">
              <TableCell className="score-row-table">Select</TableCell>
              <TableCell className="score-row-table favorite_col_star">Favorite</TableCell>
              <TableCell className="Decisions-row-tableName-prospect">
              </TableCell>
              <TableCell className="Decisions-row-tableName">
                <p className="prospect-Name-and-Title-propect">  Name & Title</p>
              </TableCell>
              <TableCell align="left" className="DecisionstableStrengthnewclass">
                <p className="DecisionstableStrength-strength">JOI Strength  </p>
              </TableCell>
              <TableCell align="left" className="employee-row-tableCompany">
                <p className="DecisionstableStrength-companynew">  Company</p>
              </TableCell>
              <TableCell align="left" className="annual-row-tableIndustry">
                Industry/ Sector
              </TableCell>
              <TableCell align="left" className="industry-row-tableStatus">
                <p className="DecisionstableStrength-strength">  JOSF Status</p>
              </TableCell>
              <TableCell
                align="left"
                className="prospects-row-tableDetails-cd">
                <p className="Com-details-prospect">  Contact Details</p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {decisionMakerData?.filter(item => !archivesList?.some(i => i?.person_id === item?.person_id))?.map((row, index) => (
              <React.Fragment key={index}>
                <Row
                  row={row}
                  selected={selectedRows.includes(row)}
                  onSelect={(firstName) => {
                    const selectedIndex = selectedRows.indexOf(firstName);
                    let newSelected = [];
                    if (selectedIndex === -1) {
                      newSelected = newSelected.concat(
                        selectedRows,
                        firstName
                      );
                    } else if (selectedIndex === 0) {
                      newSelected = newSelected.concat(
                        selectedRows.slice(1)
                      );
                    } else if (selectedIndex === selectedRows.length - 1) {
                      newSelected = newSelected.concat(
                        selectedRows.slice(0, -1)
                      );
                    } else if (selectedIndex > 0) {
                      newSelected = newSelected.concat(
                        selectedRows.slice(0, selectedIndex),
                        selectedRows.slice(selectedIndex + 1)
                      );
                    }
                    setSelectedRows(newSelected);
                  }}
                  connectionStrength={row.connectionStrength}
                  setLoading={setLoading}
                  setRowsSelect={setRowsSelect}
                  selectedOuterRows={selectedOuterRows}
                  favoritesList={favoritesList}
                  setFavoritesList={setFavoritesList}
                  listFavoriteRecords={listFavoriteRecords}
                  handleRowSelect={handleRowSelect}
                />
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="loadmore-pagination-section">
        {totalCount !== decisionMakerData?.length && <button className="btn-load-more button-loadmore-pagination" onClick={loadMore} disabled={loading}>{loading ? 'Loading...' : 'Load More'}</button>}
      </div>
      {/* </InfiniteScroll> */}
      <MessageModal open={messageModal} setOpen={setMessageModal} message={`Are you sure that you want to archive ${selectedOuterRows?.length>1 ? "these records" :"this record"}?`} handleSubmit={handleArchiveRecords} length={selectedOuterRows?.length} />
      <MessageModal open={messageModalTrack} setOpen={setMessageModalTrack} message={`Are you sure that you want to add ${selectedOuterRows?.length>1? "these records":"this record"} to tracking?`} handleSubmit={handleTrackRecords} length={selectedOuterRows?.length} />
      <MessageModal open={messageModalSalesforce} setOpen={setMessageModalSalesforce} message={`Are you sure that you want to push ${selectedOuterRows?.length>1 ?"these records":"this record"} to Salesforce?`} handleSubmit={pushToSalesForce} length={selectedOuterRows?.length} />
      <ProspectRequestModal requestUpdateRecord={requestUpdateModal} setRequestUpdateRecord={setRequestUpdateModal} profileData={selectedOuterRows[0]} peopleId={selectedOuterRows?.[0]?.id} />
    </>
  );
}
DecisionMakerTable.propTypes = {
};
Row.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  connectionStrength: PropTypes.string.isRequired,
};