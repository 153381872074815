import * as React from "react";
import PropTypes from "prop-types";
import "./Style.css";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import decisionMakerImage from "../../../../Assets/Cloudfigma.svg";
import ContactDetailList from "../../../../Components/AiLeads/ContactDetailList/ContactDetailList";
import RightSidebar from "../../../../Components/RightSiderbar/RightSiderbar";
import { Tooltip } from "@mui/material";
import axios from "axios";
import Loader from "../../../../Components/Loader/Loader";
import { toast } from "react-toastify";
import { APIUrlOne, APIUrlThree, APIUrlToken, APIUrlTwo, GetOktaAuthData, GetUserId } from "../../../../Utils/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import IndustryDropdown from "../../../../Components/AiLeads/IndustrySectorDropdown/Index";
import InfiniteScroll from "react-infinite-scroll-component";
import stargray from "../../../../Assets/Stargrey.png"
import starRed from "../../../../Assets/starRed.svg"
import * as XLSX from "xlsx";
import ProspectRequestModal from "../../../../Components/RequestUpdateModals/ProspectRequestModal";
import MessageModal from "../../../../Components/MessageModal/MessageModal";
let loaded = false;
function Row({ row, selected, onSelect, setRowsSelect, selectedOuterRows, favoritesList, setFavoritesList, listFavoriteRecords, handleRowSelect }) {
  const [open, setOpen] = React.useState(false);
  const loggedInUserId = GetUserId();
  const navigate = useNavigate();
  const [openSidebar, setOpenSidebar] = React.useState(false);
  const [dataShortestPath, setDataShortestPath] = React.useState();
  const handleRightsidebar = (event) => {
    const data = {};
    data.source_uid = Number(loggedInUserId);
    data.target_uid = Number(event);
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      data: data,
      url: `${APIUrlTwo()}/v1/shortest-path`,
    };
    axios(option)
      .then((response) => {
        if (response?.status === 200) {
          // const shortestPath = { ...response.data };
          // shortestPath.source = loggedInUserId;
          // shortestPath.target = event;
          const data = Object.values(response.data);
          setDataShortestPath(data);
          setOpenSidebar(true);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const handleFavouriteLead = (row) => {
    const data = {
      "user_id": Number(GetUserId()),
      "people_id": row.person_id
    }
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      data: data,
      url: `${APIUrlThree()}/v1/save_favourite_prospect`,
    };
    axios(option)
      .then(async (response) => {
        // setLoading(false);
        listFavoriteRecords()
        toast.success("Prospect added to favorites")
      })
      .catch(() => {
        // setLoading(false);
      });
  }
  const handleRemoveFavouriteLead = (row) => {
    const data = favoritesList?.find(item => item?.person_id == row?.person_id)
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/remove_favourite_prospect?id=${data?.favourites_id}`,
    };
    axios(option)
      .then(async (response) => {
        // setLoading(false);
        listFavoriteRecords()
        toast.success("Prospect removed from favorites")
      })
      .catch(() => {
        // setLoading(false);
      });
  }

  return (
    <TableRow className="juyds" sx={{ "& > *": { borderBottom: "unset" } }}>
      <>
        <TableCell className="checkbox-row-table-inner checkbox-row-table">
          <div className="kjhgf">
            <input
              type="checkbox"
              onChange={() => handleRowSelect(row)}
              checked={selectedOuterRows.some(item => item.person_id === row?.person_id)}
            />
          </div>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
        >
          <>
            {
              favoritesList?.some(item => item?.person_id == row?.person_id) ?
                <div className="kjhgf" onClick={() => handleRemoveFavouriteLead(row)}>
                  <img src={starRed} alt="" />
                </div> :
                <div className="kjhgf" onClick={() => handleFavouriteLead(row)}>
                  <img src={stargray} alt="" />
                </div>
            }
          </>
        </TableCell>

        <TableCell className="Decisions-row-empty" align="left" >

          <div className="Decision-maker-user-name-main-container">
            <div
              className={
                row?.decision_maker === true
                  ? "Decision-maker-user-name"
                  : "Decision-maker-user-namenoborder"
              }
            >
              <img
                className={
                  row?.suspect_status === null
                    ? "hide-image"
                    : "decisionMakerImage-new"
                }
                src={decisionMakerImage}
                alt=""
              />
              <p className="letter-heading">
                {row?.first_name?.substring(0, 1)}
                {row?.last_name?.substring(0, 1)}
              </p>
            </div>
            {/* <div className="name-and-title-text">
              <h3 className="company-name-country-prospect">
                {`${row?.first_name} ${row?.last_name}`}
              </h3>
              <p className="after-company-name-country-prospect">
                {row?.primary_job_title?.substring(0, 15) +
                  (row?.primary_job_title?.length > 10 ? "...." : "")}
              </p>
            </div> */}
          </div>
        </TableCell>
        <TableCell className="Decision-maker-userTeblesell" align="left" onClick={() => { navigate(`/Profile/${row?.person_id == 0 ? "0" : row?.person_id}`) }} style={{ cursor: "pointer" }}>
          {/* <img
            className={
              row?.suspect_status === null ? "hide-image" : "decisionMakerImage"
            }
            src={decisionMakerImage}
            alt=""
          /> */}
          <div className="Decision-maker-user-name-main-container">
            {/* <div
              className={
                row?.decision_maker === true
                  ? "Decision-maker-user-name"
                  : "Decision-maker-user-namenoborder"
              }
            >
              <p className="letter-heading">
                {row?.first_name?.substring(0, 1)}
                {row?.last_name?.substring(0, 1)}
              </p>
            </div> */}
            {/* <div className="name-and-title-text">
              <h3 className="company-name-country-prospect-added">
                {`${row?.first_name} ${row?.last_name}`}
              </h3>
              <p className="after-company-name-country-prospect-new">
                {row?.primary_job_title?.substring(0, 15) +
                  (row?.primary_job_title?.length > 10 ? "...." : "")}
              </p>
            </div> */}

            <div className="name-and-title-text">
              <h3 className="company-name-country-prospect-added">
                {`${row?.first_name} ${row?.last_name}`}
              </h3>
              <p className="after-company-name-country-prospect-new">
                {row?.primary_job_title
                  ? row.primary_job_title.substring(0, 20) +
                  (row.primary_job_title.length > 10 ? "..." : "")
                  : '-'
                }
              </p>

            </div>
          </div>
        </TableCell>
        <TableCell component="th" scope="row">
          <p className="strengthdata">{row?.strengthData?.strength ? row?.strengthData?.strength : "-"}</p>
        </TableCell>
        {/* <TableCell align="left">
          <h3 className="annual-revenue-table">{row.primary_organization}</h3>
        </TableCell> */}

        <TableCell
          align="left"
          className="table-cell-of-contact-details-dropdown-th"
        >
          <div
            className="Set-dropdown-ofIndustry"
            style={{ position: "relative" }}
          >
            <div className="email-andrelative-other-info">
              <div className="maked-component-of-dropdown-forai-leads">
                <IndustryDropdown row={row}

                />
              </div>
            </div>
          </div>
        </TableCell>

        {/* <TableCell align="left">
                    <h3 className="industry-sector-table">-</h3>
                </TableCell> */}
        <TableCell align="left">
          <div className="Suspect-table-data">
            <h3 className="industry-sector-table">
              {row?.suspect_status ? row.suspect_status : "-"}
            </h3>
          </div>
        </TableCell>
        {/* <TableCell
          align="left"
          className="contact-details-leads-to-company-profile"
        >
          <div style={{ position: "relative" }}>
            <div className="email-and-other-info">
              <div className="maked-component-of-dropdown">
                <ContactDetailList item={row} />
              </div>
            </div>
          </div>
        </TableCell> */}
        <TableCell align="left" className="table-cell-of-contact-details-dropdown-th-prospect">

          <div
            className="Set-dropdown-ofContactDetailList"
            style={{ position: "relative" }}
          >
            <div className="email-and-other-infodc">
              <div className="maked-component-of-dropdown">
                <ContactDetailList item={row} />
              </div>
            </div>
          </div>
        </TableCell>
      </>
      <TableCell className="table-cellhandleRightsidebar-newclasss">
        <div className="table-cellhandleRightsidebar-edit">
          {/* <Tooltip   className="dfvbvfe2wefvfe2wefvwfv" title={"View AI Path"}> */}
          <IconButton
            aria-label="expand row"
            size="small"
            // onClick={() => handleRightsidebar(row?.person_id)}
            className="button-collapse-table-for-company"
          >
            <RightSidebar
              row={row}
              rowid={row?.person_id}
              handleRightsidebar={handleRightsidebar}
              dataShortestPath={dataShortestPath}
              openSidebar={openSidebar}
            />
          </IconButton>
          {/* </Tooltip> */}
        </div>
      </TableCell>
    </TableRow>
  );
}
Row.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};
export default function EmployeesTable({
  tableCommingData,
  setTableCommingData,
  istableDataFilter,
  setCurrentLeadsLength,
  dataForInformation,
  setIsSalesForceTrigger, isSalesForceTrigger,
  rowData,
  setIsDecisionMakerExcel,
  isDecisionMakerExcel, archiveRecord, setArchiveRecord, trackRecord, setTrackRecord,
  setDisableRequestUpdate, requestUpdateRecord, setRequestUpdateRecord, setLeadsRequestModal, setShowSalesForce
}) {
  const userInfo = GetOktaAuthData();
  const loggedUser = userInfo?.user?.profile;
  const exportToExcel = (data, filename) => {
    const filteredData = data.map(
      ({ person_id, org_id, strengthData, ...rest }) => rest
    );
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${filename}.xlsx`);
    setIsDecisionMakerExcel(false);
  };

  const location = useLocation();
  const isComponyScreen = location?.state?.isComponyScreen;
  const [previousState,setPreviousState]=React.useState(false) 
  React.useEffect(()=>{
if(isComponyScreen){
  setPreviousState(true)
}
  },[isComponyScreen])
  
  const newdata = dataForInformation //location?.state?.data;
  const [loading, setLoading] = React.useState(false);
  const [decisionMakerData, setDecisionMakerData] = React.useState([]);
  const [decisionMakerDataCompany, setDecisionMakerDataCompany] = React.useState([]);

  const [comingOrgId, setComingOrgId] = React.useState("");
  const loggedInUserId = GetUserId();
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [strengthTableData, setStrengthTableData] = React.useState([]);
  const decisionMakerTableData = decisionMakerData;
  const [hasMore, setHasMore] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [skip, setSkip] = React.useState(0);
  const [isMakerTable, setIsMakerTable] = React.useState(false);
  const [decisionMakerDataCount, setDecisionMakerDataCount] = React.useState('');
  const [rowsSelect, setRowsSelect] = React.useState({});
  const [rowsSelectAll, setRowsSelectAll] = React.useState(false)
  const [selectedOuterRows, setSelectedOuterRows] = React.useState([])
  const [favoritesList, setFavoritesList] = React.useState([])
  const [archivesList, setArchivesList] = React.useState([])
  const [requestUpdateModal, setRequestUpdateModal] = React.useState(false)
  const [messageModal, setMessageModal] = React.useState(false)
  const [messageModalTrack, setMessageModalTrack] = React.useState(false)
  const [messageModalSalesforce, setMessageModalSalesforce] = React.useState(false)
  const getStrength = async () => {
    if (!decisionMakerTableData?.length && !decisionMakerDataCompany?.length) return;
    setLoading(true);
    const tuples = decisionMakerDataCompany?.length ? decisionMakerDataCompany.map((item) => ({
      items: [Number(GetUserId()), item?.person_id],
    })) : decisionMakerTableData.map((item) => ({
      items: [Number(GetUserId()), item?.person_id],
    }));
    const data = { tuples };
    const options = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      data: data,
      url: `${APIUrlTwo()}/v1/conn_strength`,
    };
    try {
      const response = await axios(options);
      const strengthData = response?.data;
      let updatedData=[]
      if(decisionMakerDataCompany?.length){
        updatedData = decisionMakerDataCompany?.map((item, index) => ({
          ...item,
          strengthData: strengthData[index],
        }))
      }else{
        updatedData = decisionMakerTableData?.map((item, index) => ({
          ...item,
          strengthData: strengthData[index],
        }));
      }
      
      setStrengthTableData(updatedData);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    getStrength();
  }, [decisionMakerTableData,decisionMakerDataCompany]);
  // const fetchMoreData = () => {
  //     const newSkip = skip + 50;
  //     setSkip(newSkip);
  //     aiDecisionMakerTable(newSkip);
  // };
  const fetchMoreData = () => {
    if (!decisionMakerData?.length && !decisionMakerDataCompany?.length) return;
    const hasMore = decisionMakerDataCompany?.length?.length < rowData?.people_count || decisionMakerData?.length < rowData?.people_count || decisionMakerDataCount;
    if (!hasMore) return;
    setSkip((prevskip) => prevskip + 50);
    // const newSkip = skip + 10;
    // aiDecisionMakerTable(newSkip);
  };
  React.useEffect(()=>{
    setSkip(0);
    setDecisionMakerData([])
    setDecisionMakerDataCompany([])
  },[dataForInformation])
  // React.useEffect(() => {
  //   if (orgId !== (newdata?.org_id || newdata.id || isComponyScreen)) {
  //     setSkip(0);
  //     setDecisionMakerData([])
  //   }

  // }, [newdata?.org_id, newdata.id,isComponyScreen])
  const aiDecisionMakerTableCompany = (eventName) => {
    setHasMore(false);
    setLoading(true);
    // if (orgId !== (newdata?.org_id || newdata.id) && isComponyScreen) {
    //   setOrgId(newdata?.org_id || newdata.id)
    // }

    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/decision_maker?org_id=${rowData}&limit=50&skip=${skip}`,
    };
    axios(option)
      .then((e) => {
        setDecisionMakerDataCount(e?.data?.count);
        setLoading(false);
        const comingData = e?.data?.data;

        if (comingData.length === 0) {
          setHasMore(false);
          setDecisionMakerDataCompany([]);
          setStrengthTableData([]);
        }
        else {
          if (comingData.length % 50 === 0)
            setTimeout(() => setHasMore(true), 1000);
          decisionMakerDataCompany.length === 0
            ? setDecisionMakerDataCompany(comingData)
            :
            setDecisionMakerDataCompany([...decisionMakerDataCompany, ...comingData]);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const aiDecisionMakerTable = (eventName) => {
    setHasMore(false);
    setLoading(true);
    // if (orgId !== (newdata?.org_id || newdata.id) && isComponyScreen) {
    //   setOrgId(newdata?.org_id || newdata.id)
    // }

    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/decision_maker?org_id=${rowData}&limit=50&skip=${skip}`,
    };
    axios(option)
      .then((e) => {
        setDecisionMakerDataCount(e?.data?.count);
        setLoading(false);
        const comingData = e?.data?.data;

        if (comingData.length === 0) {
          setHasMore(false);
          setDecisionMakerData([]);
          setStrengthTableData([]);
        }
        else {
          if (comingData.length % 50 === 0)
            setTimeout(() => setHasMore(true), 1000);
          decisionMakerData.length === 0
            ? setDecisionMakerData(comingData)
            :
            setDecisionMakerData([...decisionMakerData, ...comingData]);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (dataForInformation) {
      if (previousState) {
        aiDecisionMakerTableCompany();
      } else {
        aiDecisionMakerTable();
      }
    }
  }, [dataForInformation]);
  // React.useEffect(() => {
  //   if (dataForInformation) {
  //     setComingOrgId(dataForInformation?.org_id);
  //   }
  // }, [dataForInformation]);
  React.useEffect(() => {
    if (tableCommingData) {
      setCurrentLeadsLength(tableCommingData?.length);
    }
  }, [tableCommingData]);

  React.useEffect(() => {
    if (decisionMakerDataCompany?.length >= 50) {
      aiDecisionMakerTableCompany()
    }else if(decisionMakerData?.length >= 50){
      aiDecisionMakerTable();
    }
  }, [skip]);

  React.useEffect(() => {
    if (isDecisionMakerExcel) {
      exportToExcel(decisionMakerDataCompany ||decisionMakerData, "decisionmaker_exported_data");
    }
  }, [isDecisionMakerExcel]);

  const handleRowSelect = (row) => {
    setSelectedOuterRows(prevSelectedRows => {
      const isAlreadySelected = prevSelectedRows.some(item => item.person_id === row.person_id);

      if (isAlreadySelected) {
        return prevSelectedRows.filter(item => item.person_id !== row.person_id);
      } else {
        return [...prevSelectedRows, row];
      }
    });
  };
  React.useEffect(() => {
    if (rowsSelectAll) {
      const allRows = [...strengthTableData];
      setSelectedOuterRows(allRows);
    } else if (rowsSelectAll === false) {
      setSelectedOuterRows([]);
    } else if (selectedOuterRows?.length == strengthTableData?.length) {
      setRowsSelectAll(true)
    } else if (selectedOuterRows?.length < strengthTableData?.length) {
      setRowsSelectAll(false)
    }
  }, [rowsSelectAll, rowsSelect]);

  React.useEffect(() => {
    if (archiveRecord == true) {
      setMessageModal(true)
      setArchiveRecord(false)
      // handleArchiveRecords()
    }
  }, [archiveRecord])
  const listFavoriteRecords = async () => {
    const options = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/list_favourite_prospects?userid=${Number(GetUserId())}&limit=200&offset=0`,
    };
    try {
      const response = await axios(options);
      setFavoritesList(response?.data?.data)
      // setArchiveRecord(false)
      // toast.success("Prospects Archived Successfully")
    } catch (error) {
      // setLoading(false);
      toast.error(error?.response?.data?.message);
    } finally {
      // setLoading(false);
    }
  }
  const listArchiveRecords = async () => {
    const options = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/list_user_archive_prospects?userid=${Number(GetUserId())}&limit=200&offset=0`,
    };
    try {
      const response = await axios(options);
      const data = response?.data?.data?.filter(item => item?.org_id !== null)
      setArchivesList(data)
    } catch (error) {
      // setLoading(false);
      toast.error(error?.response?.data?.message);
    } finally {
      // setLoading(false);
    }
  }
  React.useEffect(() => {
    listFavoriteRecords()
    listArchiveRecords()
  }, [])
  const handleArchiveRecords = async () => {

    if (!selectedOuterRows?.length) {
      const data = [{
        user_id: Number(GetUserId()),
        // leads_id: null,
        org_id: rowData
      }]
      const options = {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          "content-type": "application/json",
          "X-API-Key": APIUrlToken()
        },
        data: data,
        url: `${APIUrlThree()}/v1/send_archive`,
      };
      try {
        const response = await axios(options);
        if (response?.data?.message === "Already archived") {
          toast.success("Organization already Archived.")

        } else {

          toast.success("Organization Archived Successfully")
        }
        setArchiveRecord(false)
        setMessageModal(false)
        setSelectedOuterRows([])
      } catch (error) {
        // setLoading(false);
        toast.error(error?.response?.data?.message);
      } finally {
        // setLoading(false);
      }
    } else {
      const data = selectedOuterRows.map(item => ({
        user_id: Number(GetUserId()),
        // leads_id: null,
        people_id: item?.person_id
      }));

      const options = {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          "content-type": "application/json",
          "X-API-Key": APIUrlToken()
        },
        data: data,
        url: `${APIUrlThree()}/v1/send_archive`,
      };
      try {
        const response = await axios(options);
        setArchiveRecord(false)
        setMessageModal(false)
        if (response?.data?.message === "Already archived") {
          toast.success("Prospects already Archived.")

        } else {

          toast.success("Prospects Archived Successfully")
        } 
        setSelectedOuterRows([])
        listArchiveRecords()
      } catch (error) {
        // setLoading(false);
        toast.error(error?.response?.data?.message);
      } finally {
        // setLoading(false);
      }
    }
  }
  React.useEffect(() => {
    if (trackRecord == true) {
      // handleTrackRecords()
      setMessageModalTrack(true)
      setTrackRecord(false)
    }
  }, [trackRecord])

  const handleTrackRecords = () => {
    if (!selectedOuterRows?.length) {
      setLoading(true);
      const data = [rowData]
      const option = {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          "X-API-Key": APIUrlToken()
        },
        data: data,
        url: `${APIUrlThree()}/v1/save_tracking_organization?user_id=${Number(GetUserId())}`,
      };
      axios(option)
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            setTrackRecord(false)
            setMessageModalTrack(false)
            toast.success("Organization Tracking Added")
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response.data.message);
        });
    } else {
      setLoading(true);
      const data = selectedOuterRows.map(item => item?.person_id)
      const option = {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          "X-API-Key": APIUrlToken()
        },
        data: data,
        url: `${APIUrlThree()}/v1/save_tracking_prospect?user_id=${Number(GetUserId())}`,
      };
      axios(option)
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            setTrackRecord(false)
            setMessageModalTrack(false)
            toast.success("Prospects Tracking Added")
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response.data.message);
        });
    }
  }

  React.useEffect(() => {
    if (selectedOuterRows?.length < 1 && requestUpdateRecord == true) {
      setDisableRequestUpdate(false)
      setLeadsRequestModal(true)
    } else if (selectedOuterRows?.length === 1 && requestUpdateRecord == true) {
      setRequestUpdateModal(true)
      setRequestUpdateRecord(false)
      setDisableRequestUpdate(false)
    } else if (selectedOuterRows?.length > 1) {
      setDisableRequestUpdate(true)
      setRequestUpdateRecord(false)
    } else {
      setDisableRequestUpdate(false)
    }
  }, [selectedOuterRows, requestUpdateRecord])

  const validateSalesforce = () => {
    if (!selectedOuterRows || selectedOuterRows?.length === 0) {
      toast.error("Please Select Records For Push To Salesforce");
      setIsSalesForceTrigger(false)
      return false;
    }
    return true;
  };
  const pushToSalesForce = () => {
    // if (!validateSalesforce()) return;
    setLoading(true);

    const ms = {
      updateData: {
        data: selectedOuterRows?.map((item) => ({
          items: [item?.person_id, "Suspect"],
        })),
      },
    };
    const transformedData = selectedOuterRows
      ?.map((i) => {
        const organizationInfo =
          decisionMakerData.find((org) => org?.org_id === i?.org_id) || {};
        return { ...i, organizationInfo };
      })
      ?.map((item) => ({
        attributes: {
          type: "Lead",
          referenceId: "rec" + item?.person_id,
        },
        LastName: (item?.last_name),
        FirstName: (item?.first_name),
        Salutation: null,
        Email: item?.email,
        Phone: item?.phone_no,
        Title: item?.primary_job_title,
        Company: item?.primary_organization,
        City: item?.organizationInfo?.location_identifiers?.split(",")[0],
        State: item?.organizationInfo?.location_identifiers?.split(",")[1],
        Country: item?.organizationInfo?.country,
        LeadSource: "JOI",
        Status: "Suspect",
        LinkedIn_Profile__c: item?.linkedin,
        Suspect_Source_Detail__c: `${loggedUser?.firstName} ${loggedUser?.lastName}`,
      }));
    const finalJson = { records: transformedData, ...ms };
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      data: finalJson,
      url: `${APIUrlOne()}/v1/add_suspects`,
    };
    axios(option)
      .then(async (response) => {
        setLoading(false);
        if (response?.status === 200) {
          toast.success("Record has been created");
          // const promiseRequest = selectedRows?.map((item) => {
          //   return ExpandHandler(item?.org_id, 1);
          // });
          // const result = await Promise.all(promiseRequest);
          // setExpandHandlerAccept(...result);
          setSelectedOuterRows([]);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message || "An Error occurred");
      });
  };

  React.useEffect(() => {
    if (isSalesForceTrigger) {
      if (!validateSalesforce()) return;
      setMessageModalSalesforce(true)
      setIsSalesForceTrigger(false)
      // pushToSalesForce();
    }
  }, [isSalesForceTrigger]);
  React.useEffect(() => {
    if (selectedOuterRows?.length) {
      const data = selectedOuterRows?.filter((item) => item.suspect_status !== null)
      if (data?.length) {

        setShowSalesForce("disable")
      } else {
        setShowSalesForce("show")
      }
    } else {
      setShowSalesForce(false)
    }
  }, [selectedOuterRows])
  return (
    <>
      {loading ? <Loader /> : null}
      <InfiniteScroll
        // rowData?.people_count (may be we need to use here)
        dataLength={decisionMakerData?.length || decisionMakerData?.length}
        next={() => fetchMoreData()}
        hasMore={hasMore}
        scrollableTarget="DecisionMaker-table-main"
      >

        {strengthTableData?.length ? (
          <TableContainer
            component={Paper}
            className="DecisionMaker-table-main"
          >
            <Table
              aria-label="collapsible table"
              className="DecisionMaker-table"
            >
              <TableHead>
                <TableRow className="table-row-ai-leads">
                  <TableCell className="checkbox-row-table-inner checkbox-row-table">
                    <div className="kjhgf">
                      <input
                        type="checkbox"
                        onChange={(e) => setRowsSelectAll(e?.target?.checked)}
                        checked={rowsSelectAll}
                      />
                    </div>
                  </TableCell>                  <TableCell className="Decisions-row-empty"><p>Favorite</p></TableCell>
                  <TableCell className="Decisions-row-empty"></TableCell>
                  <TableCell className="Decisions-row-tableNameaAndtitle">
                    <p className="title-of-company-profile-screen">

                      Name & Title
                    </p>
                  </TableCell>
                  <TableCell align="left" className="DecisionstableJOIStrength">
                    <p className="strengthdata">JOI Strength</p>
                  </TableCell>
                  {/* <TableCell
                    align="left"
                    className="employee-row-tableCompanydata"
                  >
                    <p className="company-tag-leads-to-company">Company</p>
                  </TableCell> */}
                  <TableCell
                    align="left"
                    className="annual-row-tableIndustrySector"
                  >
                    <p className="sector-heading-companyscreen">

                      Industry/ Sector
                    </p>
                  </TableCell>
                  <TableCell align="left" className="industry-row-tableStatus">
                    <p className="strengthdata">JOSF Status</p>
                  </TableCell>
                  {/* <TableCell
                    align="left"
                    className="Decisions-row-tableNameaAndtitle-leads-to-companyprofile"
                  >
                    <p className="Contact-Details-heading-leads-to-profile">
                      Contact Details
                    </p>
                  </TableCell> */}
                  <TableCell
                    align="left"
                    className="prospects-row-tableDetails-cd">
                    <p className="Com-details-prospect">  Contact Details</p>
                  </TableCell>
                  {/* <TableCell
                    align="left"
                    className="prospects-row-table"
                  ></TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {strengthTableData?.filter(item => !archivesList?.some(i => i?.person_id === item?.person_id))?.map((row, index) => (
                  <React.Fragment key={index}>
                    <Row
                      row={row}
                      selected={selectedRows.includes(row.first_name)}
                      onSelect={(firstName) => {
                        const selectedIndex = selectedRows.indexOf(firstName);
                        let newSelected = [];
                        if (selectedIndex === -1) {
                          newSelected = newSelected.concat(
                            selectedRows,
                            firstName
                          );
                        } else if (selectedIndex === 0) {
                          newSelected = newSelected.concat(
                            selectedRows.slice(1)
                          );
                        } else if (selectedIndex === selectedRows.length - 1) {
                          newSelected = newSelected.concat(
                            selectedRows.slice(0, -1)
                          );
                        } else if (selectedIndex > 0) {
                          newSelected = newSelected.concat(
                            selectedRows.slice(0, selectedIndex),
                            selectedRows.slice(selectedIndex + 1)
                          );
                        }
                        setSelectedRows(newSelected);
                      }}
                      setRowsSelect={setRowsSelect}
                      selectedOuterRows={selectedOuterRows}
                      favoritesList={favoritesList}
                      setFavoritesList={setFavoritesList}
                      listFavoriteRecords={listFavoriteRecords}
                      handleRowSelect={handleRowSelect}
                    />
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className="ai-leads-table-main">
            <div className="ai-leads-no-data-available-outter">
              <div className="ai-leads-no-data-available">
                No Data Available
              </div>
            </div>
          </div>
        )}
      </InfiniteScroll>
      <MessageModal open={messageModal} setOpen={setMessageModal} message={`Are you sure that you want to archive ${selectedOuterRows?.length > 1 ? "these records" : "this record"}?`} handleSubmit={handleArchiveRecords} length={selectedOuterRows?.length} />
      <MessageModal open={messageModalTrack} setOpen={setMessageModalTrack} message={`Are you sure that you want to add ${selectedOuterRows?.length > 1 ? "these records" : "this record"} to tracking?`} handleSubmit={handleTrackRecords} length={selectedOuterRows?.length} />
      <MessageModal open={messageModalSalesforce} setOpen={setMessageModalSalesforce} message={`Are you sure that you want to push ${selectedOuterRows?.length > 1 ? "these records" : "this record"} to Salesforce?`} handleSubmit={pushToSalesForce} length={selectedOuterRows?.length} />
      <ProspectRequestModal requestUpdateRecord={requestUpdateModal} setRequestUpdateRecord={setRequestUpdateModal} profileData={selectedOuterRows[0]} peopleId={selectedOuterRows?.[0]?.id} />

    </>
  );
}
