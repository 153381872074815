import React, { useEffect, useRef, useState } from 'react'
import "./Crie.css"
import { Box, Button, Modal, Stack } from '@mui/material';
import upload from "../../../../Assets/uploadfile.svg"
import print from "../../../../Assets/print.svg"
import download from "../../../../Assets/downloadcv.png"
import Loader from '../../../Loader/Loader';
import { APIUrlThree, APIUrlToken, GetUserId} from '../../../../Utils/Utils';
import axios from 'axios';
import moment from 'moment';
import CrieDirectPath from './CrieDirectPath';
import CrieIndirectPath from './CrieIndirectPath';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
const Crie = ({ dataForInformation, handleToPDFCrie, handlePrint }) => {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 600,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 2,
        borderRadius: "8px",
    };
  const location = useLocation();
  const isComponyScreen = location?.state?.isComponyScreen;
    const [loading, setLoading] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [selectedValue, setSelectedValue] = useState('direct');
    //Direct Path States
    const [crieData, setCrieData] = useState([])
    const [crieDataInitial, setCrieDataInitial] = useState([])
    const [crieSrcList, setCrieSrcList] = useState([])
    const [crieDstList, setCrieDstList] = useState([])
    const [joAssociateList, setJoAssociateList] = useState([])
    const [organizationAssociateList, setOrganizationAssociateList] = useState([])
    // In direct Path states 
    const [crieIndirectData, setCrieIndirectData] = useState([])
    const [joIndirectAssociateList, setJoIndirectAssociateList] = useState([])
    const [organizationIndirectAssociateList, setOrganizationIndirectAssociateList] = useState([])
    const [middleAssociateList, setMiddleAssociateList] = useState([])
    const [criejoIndirectAssociateList, setCrieJoIndirectAssociateList] = useState([])
    const [crieorganizationIndirectAssociateList, setCrieOrganizationIndirectAssociateList] = useState([])
    const [criemiddleAssociateList, setCrieMiddleAssociateList] = useState([])
    const [disabledIndirect, setDisabledIndirect] = useState("")
    const [modalOpenRefresh, setModalOpenRefresh] = useState(false)
    const [inDirectSummary, setInDirectSummary] = useState()
    const [indirectCount, setIndirectCount] = useState(0)

    const [strengthList, setStrengthList] = useState()
    const [crieStrengthList, setCrieStrengthList] = useState()
    const handleRadioChange = (event) => {
        setSelectedValue(event.target.value);
    };
    const handleModalClose = () => {
        setModalOpen(false)
    }
    const getCrieIndirectdata = () => {
        setLoading(true);
        const data = {
            "src_id": [
                process.env.REACT_APP_JOI_JO_ID
            ],
            "target_id": [
                String(dataForInformation?.org_id)
            ]
        }
        const option = {
            method: "POST",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "X-API-Key": APIUrlToken()
            },
            data: data,
            url: `${APIUrlThree()}/v1/get_initial_indirect_dropdown`,
        };
        axios(option)
            .then((e) => {
                setLoading(false);
                if (e?.status === 200) {
                    let data = e?.data?.doc
                    setDisabledIndirect(e?.data?.indirect_request || e?.data?.doc?.indirect_request)
                    setInDirectSummary(data?.summary)
                    setIndirectCount(data?.summary?.number_of_path)
                    setOrganizationIndirectAssociateList(data?.summary?.target_nodes)
                    setJoIndirectAssociateList(data?.summary?.source_nodes)
                    setMiddleAssociateList(data?.summary?.mid_nodes)
                    setCrieOrganizationIndirectAssociateList(data?.summary?.target_nodes)
                    setCrieJoIndirectAssociateList(data?.summary?.source_nodes)
                    setCrieMiddleAssociateList(data?.summary?.mid_nodes)
                    setStrengthList(data?.summary?.strengths?.map(item => ({
                        name: item, value: item
                    })))
                    setCrieStrengthList(data?.summary?.strengths?.map(item => ({
                        name: item, value: item
                    })))
                }
            })
            .catch(() => {
                setLoading(false);
            });
    }
    const getCriedata = () => {
        setLoading(true);
        const option = {
            method: "POST",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "X-API-Key": APIUrlToken()
            },
            url: `${APIUrlThree()}/v1/get_direct_path?source_id=${process.env.REACT_APP_JOI_JO_ID}&destination_id=${dataForInformation?.org_id}`,
        };
        axios(option)
            .then((e) => {
                setLoading(false);
                if (e?.status === 200) {
                    let data = e?.data
                    setCrieData(data?.data)
                    setCrieDataInitial(data?.data)
                    const dataJo = data?.src_list?.map(item => ({
                        name: item[0],
                        value: item[1]
                    }))
                    const datadest = data?.dst_list?.map(item => ({
                        name: item[0],
                        value: item[1]
                    }))
                    setJoAssociateList(dataJo)
                    setCrieSrcList(dataJo)
                    setCrieDstList(datadest)
                    setOrganizationAssociateList(datadest)
                }
            })
            .catch(() => {
                setLoading(false);
            });
    }
    useEffect(() => {
        getCriedata()
        getCrieIndirectdata()
    }, [isComponyScreen,dataForInformation])
    const handleShare = () => {
        let start = window.location.origin;
        let end = window.location.pathname?.split('/')
        let href = `${start}/${end[1]}/${end[2]}/1`
        const subject = encodeURIComponent(`${dataForInformation?.name ? dataForInformation?.name : "-"} profile`);
        const body = encodeURIComponent(`Please find the attached link.`) + '%0D%0A' + encodeURIComponent(`${href}`);
        const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=${subject}&body=${body}`;
        window.open(gmailLink, '_blank');
    }

    const handleInterestRequest = () => {
        setLoading(true);
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 60);
        const trackUntil = currentDate.toISOString().split('T')[0];
        const data = {
            request_type: "crie",
            external_id: null,
            first_name: null,
            last_name: null,
            position_title: null,
            organization_name: null,
            linkedin: null,
            organization_website: null,
            person_id: null,
            org_id: null,
            category: null,
            track_until: trackUntil,
            source_id: process.env.REACT_APP_JOI_JO_ID,
            source_name: "jobsohio",
            target_id: String(dataForInformation?.org_id),
            target_name: dataForInformation?.name,
            source: null,
            user_approval: null,
            user_id: Number(GetUserId()),
            user_email: null
        };
        const option = {
            method: "POST",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "X-API-Key": APIUrlToken(),
            },
            data: data,
            url: `${APIUrlThree()}/v1/interest_request`,
        };
        axios(option)
            .then((e) => {
                setLoading(false);
                if (e?.status === 200) {
                    setModalOpen(false);
                    setModalOpenRefresh(false)
                    getCrieIndirectdata()
                    toast.success(e?.data?.message);
                }
            })
            .catch(() => {
                setLoading(false);
            });
    };
    const calculateDaysDifference = (generatedDate) => {
        const generated = new Date(generatedDate);
        const currentDate = new Date();
        const differenceInMs = currentDate - generated;
        const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
        return differenceInDays;
    };
    return (
        <>
            {loading ? <Loader /> : null}
            <div className='DecisionMaker-table-main crit_out'>
                <div className='crie-cont-main-crie'>
                    <div className='crie-cont'>
                        <p className='company-head-crie'>Company and Relationship Intelligence Explorer (CRIE)</p>
                        <div className='crie-upload-print-download'>
                            <p onClick={() => handleShare()}> <img src={upload} alt="" />  </p>
                            <p onClick={handlePrint}> <img src={print} alt="" /> </p>
                            <p onClick={handleToPDFCrie}> <img src={download} alt="" /> </p> </div>
                    </div>
                    <div>
                        <p className='urilize-subhead-crie'>Utilize the filters below to quickly find direct paths from JobsOhio to <span style={{ textTransform: "capitalize" }}>{dataForInformation?.name}</span>.</p>

                        <p className='directpath-crie'>{crieData?.length} Direct Paths {indirectCount ? `| ${indirectCount} Indirect paths` : null} found as of {inDirectSummary?.generated_date ? moment(inDirectSummary?.generated_date).format('M.DD.YY') :moment(inDirectSummary?.generated_date).format('M.DD.YY')}</p>

                    </div>
                </div>
                {
                    selectedValue == "direct" ?
                        <CrieDirectPath
                            dataForInformation={dataForInformation}
                            crieDataInitial={crieDataInitial}
                            crieData={crieData}
                            setCrieData={setCrieData}
                            crieDstList={crieDstList}
                            crieSrcList={crieSrcList}
                            joAssociateList={joAssociateList}
                            setJoAssociateList={setJoAssociateList}
                            organizationAssociateList={organizationAssociateList}
                            setOrganizationAssociateList={setOrganizationAssociateList}
                            setModalOpen={setModalOpen}
                            setLoading={setLoading}
                            handleRadioChange={handleRadioChange}
                            selectedValue={selectedValue}
                            disabledIndirect={disabledIndirect}
                            setModalOpenRefresh={setModalOpenRefresh} />
                        :
                        <CrieIndirectPath
                            dataForInformation={dataForInformation}
                            crieData={crieIndirectData}
                            setCrieData={setCrieIndirectData}
                            crieDstList={crieorganizationIndirectAssociateList}
                            crieSrcList={criejoIndirectAssociateList}
                            crieMiddleList={criemiddleAssociateList}
                            joAssociateList={joIndirectAssociateList}
                            setJoAssociateList={setJoIndirectAssociateList}
                            organizationAssociateList={organizationIndirectAssociateList}
                            setOrganizationAssociateList={setOrganizationIndirectAssociateList}
                            strengthList={strengthList}
                            setStrengthList={setStrengthList}
                            crieStrengthList={crieStrengthList}
                            setLoading={setLoading}
                            handleRadioChange={handleRadioChange}
                            middleAssociateList={middleAssociateList}
                            setMiddleAssociateList={setMiddleAssociateList}
                            selectedValue={selectedValue}
                            disabledIndirect={disabledIndirect}
                            setIndirectCount={setIndirectCount}
                            inDirectSummary={inDirectSummary}
                            getCrieIndirectdata={getCrieIndirectdata}
                            setModalOpenRefresh={setModalOpenRefresh} />
                }
                <Modal
                    open={modalOpen}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className="vrufyerv">
                        <Box sx={style}>
                            <div className="modal-head-heading-crossicon">
                                <div className='crie-cont crie_direct'>Company and Relationship Intelligence Explorer (CRIE)</div>
                            </div>
                            <div className='crie-head crie-cont'> We will locate all in-direct connection paths and notify you when ready </div>
                            <Stack>
                                <div className="button-cancel-save-modal">
                                    <Button
                                        variant="type"
                                        className="cancel-button-modal"
                                        onClick={() => handleModalClose()}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                    onClick={handleInterestRequest}
                                        variant="type"
                                        className="save-button-modal">
                                        Submit
                                    </Button>
                                </div>
                            </Stack>
                        </Box>
                    </div>
                </Modal>
                <Modal
                    open={modalOpenRefresh}
                    onClose={() => setModalOpenRefresh(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className="vrufyerv">
                        <Box sx={style}>
                            <div className="modal-head-heading-crossicon">
                                <div className='crie-cont crie_direct'>Company and Relationship Intelligence Explorer (CRIE)</div>
                            </div>
                            <div className='crie-head crie-cont'>
                                {
                                    calculateDaysDifference(inDirectSummary?.generated_date) < 30 ? "The paths you requested were generated less than 30 days ago. Generating new ones will take approximately one day and may not result in new paths or additional information. Do you want to proceed with this request?": "Are you sure you want to refresh this request?"
                                }
                                 </div>
                            <Stack>
                                <div className="button-cancel-save-modal">
                                    <Button
                                        variant="type"
                                        className="cancel-button-modal"
                                        onClick={() => setModalOpenRefresh(false)}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="type"
                                        className="save-button-modal" onClick={handleInterestRequest} >
                                        Yes
                                    </Button>
                                </div>
                            </Stack>
                        </Box>
                    </div>
                </Modal>
            </div>
        </>
    )
}
export default Crie