import React, { useState } from "react";
import './Style.css'
import Layout from "../../Components/Layout/Layout";
import JoiOverview from '../../Assets/JOI_overview 1.png';
import JoiTutorial from '../../Assets/JOI_tutorial.png';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import favimg from "../../Assets/Favorites-Joi-Traing.svg"
import archive from "../../Assets/archive-joi-training.svg"
import document from "../../Assets/doc-icon.svg"
import { Link } from "react-router-dom";
import universalsearch from "../../Assets/universal-search-joi-training.svg"
import helpcenter from "../../Assets/help-notifications-joi-training.png"
import prospects from "../../Assets/prospect-joi-training.svg"
import crie from "../../Assets/CRIE-joi-training.svg";
import dataenrich from "../../Assets/data-enrich-joi-training.svg";
import dashboadjoi from "../../Assets/dashboard-joi-training.svg";
import competitiveintell from "../../Assets/competitive-intel-joi-training.svg";
import aipath from "../../Assets/AI-Path_joi-training.jpg"
import aiprofile from "../../Assets/ai-profile_joi-training.jpg"
import salesforce from "../../Assets/Push-to-JOSF-joi-training.jpg"


import { AI_PATH_JOB_AID, AI_PROFILE_JOB_AID, ARCHIVING_JOB_AID, COMPETITIVE_INTEL_JOB_AID, CRIE_JOB_AID, DASHBOARD_JOB_AID, DATA_ENRICH_JOB_AID, FAVORITES_JOB_AID, HELP_CENTER_JOB_AID, JOB_AID_LINKS, PERSONALIZES_DASHBOARD_JOB_AID, PROSPECT_DETAILS_JOB_AID, PUSH_TO_SALESFORCE_JOB_AID, UNIVERSAL_SEARCH_JOB_AID, VIDEO_LINKS } from "../../Utils/Constants";
const JoiTraining = () => {
    const [joiVideo, setJoiVideo] = useState();
    const [joiAidLinks, setJoiAidLinks] = useState();
    const [videoOpen, setVideoOpen] = useState(false);
    const [overviewVideoModal, setOverviewVideoModal] = React.useState(false);
    const [overViewTutorial, setOverViewTutorial] = React.useState(false);
    const handleOpenOverview = () => setOverviewVideoModal(true);
    const handleCloseOverview = () => setOverviewVideoModal(false);
    const handleOpenTraining = () => setOverViewTutorial(true);
    const handleCloseTraining = () => setOverViewTutorial(false);
    const openVideoOverview = (item) => {
        setJoiVideo(item)
        handleOpenOverview(item);
    };
    const openVideoTraining = () => {
        handleOpenTraining();
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #ffffff',
        boxShadow: 24,
        p: 4,
    };
    return (
        <>
            <Layout>
                <div>
                    <div className="main-joi-cards-jt"  >
                        <div className="outer-card-jt" >
                            <div className="cards-inner-jt">
                                <p className="title-card-jt">Favorites</p>
                                <p className="joi-training-vdo">  <img src={favimg} alt="" onClick={() => openVideoOverview("FAVORITES_VIDEO")} /></p>
                                <p className="description-cards-jt">The <span className="text-red-jt">Favorites</span> feature allows you to easily select lead(s) or prospect(s)as a favorite and review those you have identified as favorites, quickly.</p>
                                <p className="vd-title-jt"> Video Length: 2:10</p>
                                <div className="doc-icon-text">
                                    <img src={document} alt="" />
                                    <a
                                        className="video-lenght-jt"
                                        href={FAVORITES_JOB_AID}
                                         download
                                        rel="noopener noreferrer"
                                    >
                                        Download Job Aid
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="outer-card-jt" >
                            <div className="cards-inner-jt">
                                <p className="title-card-jt">Archiving</p>
                                <p className="joi-training-vdo">  <img src={archive} alt="" onClick={() => openVideoOverview("ARCHIVE_VIDEO")} /></p>
                                <p className="description-cards-jt">The <span className="text-red-jt">Archive</span> feature allows you to:
                                    Remove a prospect or lead from your JOI account.
                                    Easily see which prospects or leads have been archived under your personal profile.</p>
                                <p className="vd-title-jt"> Video Length: 1:49</p>
                                <div className="doc-icon-text">  <img src={document} alt="" />
                                    <a
                                        className="video-lenght-jt"
                                        href={ARCHIVING_JOB_AID}
                                        download
                                        rel="noopener noreferrer"
                                    >
                                        Download Job Aid
                                    </a>  </div>
                            </div>
                        </div>
                        <div className="outer-card-jt" >
                            <div className="cards-inner-jt">
                                <p className="title-card-jt">Universal Search</p>
                                <p className="joi-training-vdo">  <img src={universalsearch} alt="" onClick={() => openVideoOverview("UNIVERSAL_SEARCH_VIDEO")} /></p>
                                <p className="description-cards-jt">The JobsOhio Intelligence (JOI) tool allows you to search for an organization or prospect at the top of the page in the search bar.</p>
                                <p className="vd-title-jt">Video Length: 1:50</p>
                                <div className="doc-icon-text">  <img src={document} alt="" /> <a
                                    className="video-lenght-jt"
                                    href={UNIVERSAL_SEARCH_JOB_AID}
                                    download
                                    rel="noopener noreferrer"
                                >
                                    Download Job Aid
                                </a> </div>
                            </div>
                        </div>

                    </div>
                    <div className="main-joi-cards-jt"  >
                        <div className="outer-card-jt" >
                            <div className="cards-inner-jt">
                                <p className="title-card-jt">Help Center & Notifications</p>
                                <p className="joi-training-vdo">  <img src={helpcenter} alt="" onClick={() => openVideoOverview("HELP_CENTER_VIDEO")} /></p>
                                <p className="description-cards-jt">JOI's <span className="text-red-jt">Help Center</span> provides FAQs, how-to videos, and contact options for help. <span className="text-red-jt">Notification</span> alerts keep you informed about your Leads & Prospects.</p>
                                <p className="vd-title-jt">Video Length: 1:17</p>
                                <div className="doc-icon-text">  <img src={document} alt="" /> <a
                                    className="video-lenght-jt"
                                    href={HELP_CENTER_JOB_AID}
                                    download
                                    rel="noopener noreferrer"
                                >
                                    Download Job Aid
                                </a>  </div>
                            </div>
                        </div>
                        <div className="outer-card-jt" >
                            <div className="cards-inner-jt">
                                <p className="title-card-jt">Prospects Details</p>
                                <p className="joi-training-vdo">  <img src={prospects} alt="" onClick={() => openVideoOverview("PROSPECT_DETAILS_VIDEO")} /></p>
                                <p className="description-cards-jt">The <span className="text-red-jt">Prospect Details</span> page is a singular view for all prospect information.</p>
                                <p className="vd-title-jt"> Video Length: 1:49</p>
                                <div className="doc-icon-text">  <img src={document} alt="" /> <a
                                    className="video-lenght-jt"
                                    href={PROSPECT_DETAILS_JOB_AID}
                                    download
                                    rel="noopener noreferrer"
                                >
                                    Download Job Aid
                                </a>  </div>
                            </div>
                        </div>
                        <div className="outer-card-jt" >
                            <div className="cards-inner-jt">
                                <p className="title-card-jt">CRIE</p>
                                <p className="joi-training-vdo">  <img src={crie} alt="" onClick={() => openVideoOverview("CRIE_VIDEO")} /></p>
                                <p className="description-cards-jt">The <span className="text-red-jt">Company Relationship Intelligence Explorer (CRIE)</span> feature allows a user to search for a company and show who from JobsOhio has relationships with employees at that company.</p>
                                <p className="vd-title-jt">Video Length: 6:33</p>
                                <div className="doc-icon-text">  <img src={document} alt="" /> <a
                                    className="video-lenght-jt"
                                    href={CRIE_JOB_AID}
                                    download
                                    rel="noopener noreferrer"
                                >
                                    Download Job Aid
                                </a>  </div>
                            </div>
                        </div>

                    </div>
                    <div className="main-joi-cards-jt" >
                        <div className="outer-card-jt" >
                            <div className="cards-inner-jt">
                                <p className="title-card-jt">Data Enrich</p>
                                <p className="joi-training-vdo">  <img src={dataenrich} alt="" onClick={() => openVideoOverview("DATA_ENRICH_VIDEO")} /></p>
                                <p className="description-cards-jt">Need more information on potential prospects or organizations? <span className="text-red-jt">Data Enrich</span> can fill those gaps and deliver the contact information you're looking for.</p>
                                <p className="vd-title-jt"> Video Length: 2:10</p>
                                <div className="doc-icon-text">  <img src={document} alt="" /> <a
                                    className="video-lenght-jt"
                                    href={DATA_ENRICH_JOB_AID}
                                    download
                                    rel="noopener noreferrer"
                                >
                                    Download Job Aid
                                </a> </div>
                            </div>
                        </div>
                        <div className="outer-card-jt" >
                            <div className="cards-inner-jt">
                                <p className="title-card-jt">Personalized Dashboard</p>
                                <p className="joi-training-vdo">  <img src={dashboadjoi} alt="" onClick={() => openVideoOverview("PERSONALIZED_DASHBOARD_VIDEO")} /></p>
                                <p className="description-cards-jt">Your <span className="text-red-jt">Personalized Dashboard</span> tracks prospects, organizations, competitive states and news & events. The data is analyzed to provide crucial insights tailored to JobsOhio’s goals.</p>
                                <p className="vd-title-jt"> Video Length: 3:29</p>
                                <div className="doc-icon-text">  <img src={document} alt="" /> <a
                                    className="video-lenght-jt"
                                    href={PERSONALIZES_DASHBOARD_JOB_AID}
                                    download
                                    rel="noopener noreferrer"
                                >
                                    Download Job Aid
                                </a>  </div>
                            </div>
                        </div>
                        <div className="outer-card-jt" >
                            <div className="cards-inner-jt">
                                <p className="title-card-jt">Competitive Intelligence</p>
                                <p className="joi-training-vdo">  <img src={competitiveintell} alt="" onClick={() => openVideoOverview("COMPETITIVE_INTEL_VIDEO")} /></p>
                                <p className="description-cards-jt">The <span className="text-red-jt">Competitive Intelligence</span> - Tracking feature allows a user to track a lead or a prospect and stay updated on their latest news.</p>
                                <p className="vd-title-jt">Video Length: 1:50</p>
                                <div className="doc-icon-text">  <img src={document} alt="" />
                                    <a
                                        className="video-lenght-jt"
                                        href={COMPETITIVE_INTEL_JOB_AID}
                                        download
                                        rel="noopener noreferrer"                                >
                                        Download Job Aid
                                    </a> </div>
                            </div>
                        </div>

                        

                    </div>

                    <div className="main-joi-cards-jt" >
                        <div className="outer-card-jt" >
                            <div className="cards-inner-jt">
                                <p className="title-card-jt">AI Path</p>
                                <p className="joi-training-vdo">  <img src={aipath} alt="" className="joi_tr_bd" onClick={() => openVideoOverview("AI_PATH_VIDEO")} /></p>
                                <p className="description-cards-jt">
                                     <span className="text-red-jt">AI Paths</span> AI Paths show the connection between two individuals within the JobsOhio Intelligence (JOI) database.</p>
                                <p className="vd-title-jt"> Video Length: 3:09</p>
                                <div className="doc-icon-text">  <img src={document} alt="" /> <a
                                    className="video-lenght-jt"
                                    href={AI_PATH_JOB_AID}
                                    download
                                    rel="noopener noreferrer"
                                >
                                    Download Job Aid
                                </a> </div>
                            </div>
                        </div>
                        <div className="outer-card-jt" >
                            <div className="cards-inner-jt">
                                <p className="title-card-jt">AI Profile</p>
                                <p className="joi-training-vdo">  <img src={aiprofile} className="joi_tr_bd" alt="" onClick={() => openVideoOverview("AI_PROFILE_VIDEO")} /></p>
                                <p className="description-cards-jt"> <span className="text-red-jt">AI Profile</span>  is a saved search that will allow you to quickly find prospects using your predetermined filters.</p>
                                <p className="vd-title-jt"> Video Length: 3:56</p>
                                <div className="doc-icon-text">  <img src={document} alt="" /> <a
                                    className="video-lenght-jt"
                                    href={AI_PROFILE_JOB_AID}
                                    download
                                    rel="noopener noreferrer"
                                >
                                    Download Job Aid
                                </a>  </div>
                            </div>
                        </div>
                        <div className="outer-card-jt" >
                            <div className="cards-inner-jt">
                                <p className="title-card-jt">Push to Salesforce</p>
                                <p className="joi-training-vdo">  <img className="joi_tr_bd" src={salesforce} alt="" onClick={() => openVideoOverview("PUSH_TO_JOSF_VIDEO")} /></p>
                                <p className="description-cards-jt">Push a prospect directly into <span className="text-red-jt">Salesforce</span> (JOSF)</p>
                                <p className="vd-title-jt">Video Length: 2:00</p>
                                <div className="doc-icon-text">  <img src={document} alt="" />
                                    <a
                                        className="video-lenght-jt"
                                        href={PUSH_TO_SALESFORCE_JOB_AID}
                                        download
                                        rel="noopener noreferrer"                                >
                                        Download Job Aid
                                    </a> </div>
                            </div>
                        </div>

                        

                    </div>

                </div>
                {/* <div className="Faqscreen-parent parent-joiTraining">
                    <img src={JoiOverview} alt="joi-overview-img" onClick={openVideoOverview}
                        style={{ cursor: 'pointer' }}></img>
                    <img src={JoiTutorial} alt="joi-tutorial-img" className="joi-tutorial-img" onClick={openVideoTraining} style={{ cursor: 'pointer' }}></img>
                </div> */}
                {/* overview video modal */}
                <Modal
                    open={overviewVideoModal}
                    onClose={handleCloseOverview}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="modal-joiTraining-videos"
                >
                    <Box sx={style} className="box-video-modals">
                        <div className="box-section-relative">
                            <div className="cross-icon-section" onClick={handleCloseOverview}>
                                <CloseIcon />
                            </div>
                            <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                                <iframe
                                    src={VIDEO_LINKS[joiVideo]}
                                    frameBorder="0"
                                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                                    style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                                    title="JOI PILOT Overview"
                                ></iframe>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </Layout>
        </>
    )
}
export default JoiTraining;
