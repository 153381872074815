import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import ContactDetailList from "../../AiLeads/ContactDetailList/ContactDetailList";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import RightSidebar from "../../RightSiderbar/RightSiderbar";
import axios from "axios";
import Loader from "../../Loader/Loader";
import { toast } from "react-toastify";
import {
  APIUrlOne,
  APIUrlThree,
  APIUrlToken,
  APIUrlTwo,
  GetOktaAuthData,
  GetUserId,
} from "../../../Utils/Utils";
import InfiniteScroll from "react-infinite-scroll-component";
import * as InfiniteScrollMain from "react-infinite-scroller";
import * as XLSX from "xlsx";
import { useState } from "react";
import IndustryDropdown from "../../AiLeads/IndustrySectorDropdown/Index";
import starRed from "../../../Assets/starRed.svg"
import Stargrey from "../../../Assets/Stargrey.png"
import MessageModal from "../../MessageModal/MessageModal";

function Row({
  key,
  row,
  onSelect,
  ExpandHandler,
  expandHandlerAccept,
  selectedRows,
  setSelectedRows,
  setPageInner,
  pageInner,
  openRowId,
  hasMoreInner,
  onLoadApi,
  setHasMoreInner,
  setopenRowId,
  totalPages,
  setRowsSelectOuter, selectedOuterRows, favoritesList, setFavoritesList, listFavoriteRecords,
  archivesList, listArchiveRecords, handleRowSelect,showLeadsProspects
}) {

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [innerTableData, setInnerTAbleData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openSidebar, setOpenSidebar] = React.useState(false);
  const [dataShortestPath, setDataShortestPath] = React.useState();
  const [userDetails, setUserDetails] = useState();
  const [scrollPagination, setScrollPagination] = useState(false);

  const loggedInUserId = GetUserId();
  const handleRightsidebar = (event) => {
    const data = {};
    data.source_uid = Number(loggedInUserId);
    data.target_uid = Number(event?.person_id);
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      data: data,
      url: `${APIUrlTwo()}/v1/shortest-path`,
    };
    axios(option)
      .then((response) => {
        if (response?.status === 200) {
          const data = Object.values(response.data);
          setDataShortestPath(data);
          setOpenSidebar(true);
          setUserDetails(event);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const getColor = (aiScore) => {
    if (aiScore > 80) {
      return "green";
    } else if (aiScore >= 50 && aiScore <= 80) {
      return "yellow";
    } else {
      return "red";
    }
  };
  const color = getColor(row.ai_score);
  const [rowsSelect, setRowsSelect] = useState(false);
  const handleAllSelectCollapsible = () => {
    if (rowsSelect) {
      const newList = innerTableData
        ?.map((info) => ({ ...info, organizationInfo: row }))
      setSelectedRows(newList);
    } else {
      setSelectedRows([]);
    }
  };

  const onExpand = (event) => {
    setLoading(true);
    onLoadApi(event)
      .then((e) => {
        const childData = e;
        getStrength(childData, true);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const ExpandHandlerChild = (event) => {
    setLoading(true);
    ExpandHandler(event)
      .then((e) => {
        const childData = e;
        getStrength(childData);

        setLoading(false);
        if (e?.status === 200) {
          const innerdata = e?.data?.data;
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (expandHandlerAccept?.length > 0) {
      setInnerTAbleData([...expandHandlerAccept]);
    }
  }, [expandHandlerAccept]);

  const getStrength = async (childData, isExpand) => {
    if (!childData?.length) {
      setLoading(false);
      return;
    }
    setLoading(true);

    const tuples = childData?.map((item) => ({
      // items: [Number(loggedInUserId), item.person_id],
      items: [Number(loggedInUserId), item.person_id],
    }));
    const data = { tuples };
    const options = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      data: data,
      url: `${APIUrlTwo()}/v1/conn_strength`,
    };

    try {
      const response = await axios(options);
      const strengthData = response?.data;

      const updatedData = childData?.map((item, index) => ({
        ...item,
        strengthData: strengthData[index],
      }));

      if (isExpand) {
        setInnerTAbleData([...updatedData]);
      } else {
        setInnerTAbleData([...innerTableData, ...updatedData]);
      }

      if (open) {
      } else {
        setOpen(!open);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    } finally {
      setTimeout(() => {
        setLoading(false);
        setScrollPagination(true);
      }, 0);
      setTimeout(() => {
        setHasMoreInner(true);
      }, 1000);
    }
  };

  const clickHandler = (event) => {
    navigate(`/companyprofilescreen/${event?.org_id}/0`);
  };

  React.useEffect(() => {
    if (
      innerTableData?.length >= 50 &&
      row.org_id === openRowId &&
      pageInner > 1 &&
      pageInner <= Math.ceil(pages)
    ) {
      setHasMoreInner(false);
      ExpandHandlerChild(openRowId);
    }
  }, [pageInner]);

  const isChecked = (id) => {
    return selectedRows.some((info) => info.person_id === id);
  };

  const pages = row.people_count / 50;
  const closeExpandHandler = () => {
    setInnerTAbleData([]);
    setOpen(false);
    setopenRowId("");
    setScrollPagination(false);
  };

  React.useEffect(() => {
    handleAllSelectCollapsible();
  }, [rowsSelect]);
  const ref = React.useRef(null);
  const isBranchLocationsDisabled = true;

  const loadMore = () => {
    setLoading(true);
    setPageInner(prevPageInner => prevPageInner + 1);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };
  const handleFavouriteLead = (row) => {
    const data = {
      "user_id": Number(GetUserId()),
      "leads_id": row.org_id
    }
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      data: data,
      url: `${APIUrlThree()}/v1/save_favourite_leads`,
    };
    axios(option)
      .then(async (response) => {
        setLoading(false);
        listFavoriteRecords()
        toast.success("Organization added to favorites")
      })
      .catch(() => {
        setLoading(false);
      });
  }
  const handleRemoveFavouriteLead = (row) => {
    const data = favoritesList?.find(item => item?.org_id == row?.org_id)
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      // data: data,
      url: `${APIUrlThree()}/v1/remove_favourite_leads?id=${data?.favourites_id}`,
    };
    axios(option)
      .then(async (response) => {
        setLoading(false);
        listFavoriteRecords()
        toast.success("Organization removed from favorites")
      })
      .catch(() => {
        setLoading(false);
      });
  }
  return (
    <React.Fragment key={key}>
      {loading ? <Loader /> : null}
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        style={{ cursor: "pointer" }}
      >
        <>
          <TableCell className="checkbox-row-table-inner checkbox-row-table">
            <div className="kjhgf">
            {
                          showLeadsProspects==="pros" ?
                          <input
                            type="checkbox"
                           disabled
                          />:
              <input
                type="checkbox"
                onChange={() => handleRowSelect(row)}
                checked={selectedOuterRows.some(item => item.id === row?.id)}
              />
            }
            </div>
          </TableCell>
          <TableCell
            component="th"
            scope="row"
          >
            <>
              {
                favoritesList?.some(item => item?.org_id == row?.org_id) ?
                  <div className="kjhgf" onClick={() => handleRemoveFavouriteLead(row)}>
                    <img src={starRed} alt="" />
                  </div> :
                  <div className="kjhgf" onClick={() => handleFavouriteLead(row)}>
                    <img src={Stargrey} alt="" />
                  </div>
              }
            </>
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            onClick={() => clickHandler(row)}
          >
            <div className="kjhgf">
              <div
                className="ai-score-background"
                style={{
                  background:
                    color === "red"
                      ? "#DA291C"
                      : color === "yellow"
                        ? "#EAB121"
                        : color === "green"
                          ? "#0EB93E"
                          : null,
                }}
              >
                {/* {row.ai_score ? row.ai_score : "-"} */}
                {row?.ai_score !== null && row?.ai_score !== undefined
                  ? row?.ai_score
                  : "NA"}
              </div>
            </div>
          </TableCell>
          <TableCell
            align="left"
            className="companylocation"
            onClick={() => clickHandler(row)}
          >
            <h3 className="company-name-country-prospect">
              {row.name ? row.name : "-"}
            </h3>
            <p className="after-company-name-country-prospect">
              {row.location_identifiers ? row.location_identifiers : "-"}
            </p>
          </TableCell>
          <TableCell align="center" onClick={() => clickHandler(row)}>
            <div className="section-employee-size">
              <h3 className="employee-size-table">
                {row.num_employees ? row.num_employees : "-"}
              </h3>
            </div>
          </TableCell>
          <TableCell align="left" onClick={() => clickHandler(row)}>
            <h3 className="annual-revenue-table">
              {row?.revenue_range ? row?.revenue_range : "-"}
            </h3>
          </TableCell>
          <TableCell
            align="left"
            className="table-cell-of-contact-details-dropdown-th"
          >
            <div
              className="Set-dropdown-ofIndustry"
              style={{ position: "relative" }}
            >
              <div className="email-andrelative-other-info">
                <div className="maked-component-of-dropdown-forai-leads">
                  <IndustryDropdown row={row} />
                </div>
              </div>
            </div>
          </TableCell>
        </>
        <TableCell align="left" onClick={() => clickHandler(row)}>
          <h3 className="employee-size-table">
            {row.people_count ? row.people_count : "-"}
          </h3>
        </TableCell>
        <TableCell className="collapse-expand-main-header">
          <Tooltip title={open ? "Collapse" : "Expand"}>
            {open ? (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => closeExpandHandler()}
                className="button-collapse-table-main"
              >
                <RemoveRoundedIcon className="icon-collapse-table" />
              </IconButton>
            ) : (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {
                  setInnerTAbleData([]);
                  setHasMoreInner(false);
                  setTimeout(() => {
                    if (openRowId !== row?.org_id) {
                      setLoading(true);
                      setPageInner(1);
                      onExpand(row?.org_id);
                    }
                  }, 0);
                }}
                className="button-collapse-table-main"
              >
                <AddIcon className="icon-collapse-table" />
              </IconButton>
            )}
          </Tooltip>
        </TableCell>
      </TableRow>
      {
        innerTableData?.length ?
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box
                  sx={{ margin: 1 }}
                  id="scrollableDiv"
                  className="ai-leads-table-inner"
                  ref={ref}
                >
                  <TableContainer component={Paper}>
                    <Table
                      aria-label="collapsible table"
                      className="ai-leads-table"
                    >
                      <TableHead>
                        <TableRow className="row-head">
                          <TableCell className="checkbox-row-table-inner checkbox-row-table">
                          {
                          showLeadsProspects==="pros" ?
                          <input
                            type="checkbox"
                           disabled
                          />:
                            <input
                              type="checkbox"
                              onChange={(e) => setRowsSelect(e?.target?.checked)}
                              checked={rowsSelect}
                            />
                          }
                          </TableCell>
                          <TableCell className="empty-name-and-title">
                          </TableCell>

                          <TableCell className="company-row-table-inner-name-and-other">
                            <p className="title-name-inner-lead-table">Name & Title</p>
                          </TableCell>
                          <TableCell
                            align="left"
                            className="score-row-table-New-Data"
                          >
                            JOI Strength
                          </TableCell>
                          <TableCell
                            align="left"
                            className="employee-row-table-innerIndustryAndSector"
                          >
                            <p className="sector-industry"> Industry/ Sector</p>
                          </TableCell>
                          <TableCell
                            align="left"
                            className="annual-row-table-NewJOSFStatus"
                          >
                            JOSF Status
                          </TableCell>
                          <TableCell align="left" className="industry-row-table-inner-table">
                            Contact details
                          </TableCell>
                          <TableCell
                            align="left"
                            className="industry-row-table-inner-table-last"
                          ></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {innerTableData?.map((item, index) => {
                          const firstName = item?.first_name;
                          const lastName = item?.last_name;
                          const decision_maker = item.decision_maker === true;
                          const concatedName = `${firstName
                            ? firstName
                              ?.split(" ")
                              ?.find((item) => !item.includes("("))
                              ?.charAt(0)
                            : ""
                            }${lastName
                              ? lastName
                                ?.split(" ")
                                ?.find((item) => !item.includes("("))
                                ?.charAt(0)
                              : ""
                            }`;

                          const isSelected = isChecked(item.person_id);
                          return (
                            <React.Fragment key={index}>
                              <TableRow
                                key={index}
                                sx={{ "& > *": { borderBottom: "unset" } }}
                              >
                                <TableCell className="checkbox-row-table">
                                {
                          showLeadsProspects==="pros" ?
                          <input
                            type="checkbox"
                           disabled
                          />:
                                    <input
                                      type="checkbox"
                                      checked={isSelected}
                                      onChange={() => onSelect(item)}
                                    />
                                }
                                </TableCell>
                                <TableCell align="left" className="empty-name-and-title">
                                  <div className="name-title-inner-table-ofAi-leads">
                                    <div className="relation-section-inner">
                                      <div
                                        className={
                                          decision_maker
                                            ? "decision-maker-class"
                                            : "create-name-img"
                                        }
                                      >
                                        <p className="name-heading">
                                          {concatedName}
                                        </p>
                                        <img
                                          className={
                                            item.suspect_status === null
                                              ? "hide-image"
                                              : "../images/salesforce-logo.svg"
                                          }
                                          src="../images/salesforce-logo.svg"
                                          alt=""
                                        />
                                      </div>



                                    </div>
                                  </div>
                                </TableCell>

                                <TableCell align="left" className="company-row-table-inner-name-and-other">
                                  <div className="name-title-inner-table-ofAi-leads">
                                    <div className="relation-section-inner">
                                      <div className="name-and-designation">
                                        <p className="name-in-acordian">{`${item?.first_name
                                          ? item?.first_name
                                          : "-"
                                          } ${item?.last_name ? item?.last_name : "-"
                                          }`}</p>
                                        <p className="designation-in-acordian">
                                          {item?.primary_job_title?.length
                                            ? item?.primary_job_title?.length > 20
                                              ? item?.primary_job_title.substr(
                                                0,
                                                30
                                              ) + "..."
                                              : item?.primary_job_title
                                            : "-"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <p className="j-strength-cell">
                                    {item?.strengthData?.strength
                                      ? item?.strengthData?.strength
                                      : "-"}
                                  </p>
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="table-cell-of-contact-details-dropdown-th"
                                >
                                  <div
                                    className="Set-dropdown-ofIndustry"
                                    style={{ position: "relative" }}
                                  >
                                    <div className="email-andrelative-other-info">
                                      <div className="maked-component-of-dropdown-forai-leads">
                                        <IndustryDropdown row={row} />
                                      </div>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell align="left">
                                  <div className="suspect-and-arrow-in-accordian">
                                    <p>
                                      {item?.suspect_status
                                        ? item?.suspect_status
                                        : "-"}
                                    </p>
                                  </div>
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="table-cell-of-contact-details-dropdown-th"
                                >
                                  <div
                                    className="Set-dropdown-ofContactDetailList"
                                    style={{ position: "relative" }}
                                  >
                                    <div className="email-and-other-infodc">
                                      <div className="maked-component-of-dropdown">
                                        <ContactDetailList item={item} />
                                      </div>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell className="left-arrow-main-cell-container">
                                  <IconButton
                                    className="egberh"
                                    aria-label="expand row"
                                    size="small"
                                  >
                                    <RightSidebar
                                      handleRightsidebar={handleRightsidebar}
                                      rowid={item}
                                      dataShortestPath={dataShortestPath}
                                      openSidebar={openSidebar}
                                      userDetails={userDetails}
                                    />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          );
                        })}
                      </TableBody>
                    </Table>
                    {/* </InfiniteScrollMain> */}
                  </TableContainer>
                  {
                    innerTableData?.length >= 50 ?
                      <div className="loadmore-pagination-section">
                        {totalPages !== pageInner && <button className="btn-load-more button-loadmore-pagination" onClick={loadMore} disabled={loading}>{loading ? 'Loading...' : 'Load More'}</button>}
                      </div> : null
                  }

                </Box>
              </Collapse>
            </TableCell>
          </TableRow> : null
      }
    </React.Fragment>
  );
}
Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default function ArchivedOrganization({
  setIsDecisionMakerExcel,
  isDecisionMakerExcel, setIsSalesForceTrigger,
  isSalesForceTrigger, unArchivedRecord, setUnArchivedRecord, trackRecord, setTrackRecord,
  selectedOuterRows, setSelectedOuterRows,showLeadsProspects,selectedRows, setSelectedRows,setShowSalesForce
}) {
  const userInfo = GetOktaAuthData();
  const loggedUser = userInfo?.user?.profile;
  const [loading, setLoading] = React.useState(false);
  const [hasMoreInner, setHasMoreInner] = React.useState(false);
  // const [page, setPage] = React.useState(0);
  const [expandHandlerAccept, setExpandHandlerAccept] = useState([]);
  const [pageInner, setPageInner] = React.useState(1);
  const [openRowId, setopenRowId] = useState("");
  const [totalPages, setTotalPages] = useState(10);
  const [rowsSelectOuter, setRowsSelectOuter] = React.useState({});
  // const [selectedOuterRows, setSelectedOuterRows] = React.useState([])
  const [favoritesList, setFavoritesList] = React.useState([])
  const [archivesList, setArchivesList] = React.useState([])
  const [apiHit, setApiHit] = React.useState(false)
  const [rowsSelectAll, setRowsSelectAll] = React.useState(false)
  const [messageModalTrack, setMessageModalTrack] = React.useState(false)
  const [hitApi, setHitApi] = React.useState(false)
  const [hitApiInner, setHitApiInner] = React.useState(false)
  const [messageModalSalesforce, setMessageModalSalesforce] = React.useState(false)

  const validateSalesforce = () => {
    if (!selectedRows || selectedRows?.length === 0) {
      // toast.error("Please Select Records For Push To Salesforce");
      setIsSalesForceTrigger(false)
      return false;
    }
    return true;
  };
  const ExpandHandler = (event, page) => {
    setopenRowId(event);

    const option = {
      method: "GET",
      headers: {
        "content-type": "plain/text",
        "X-API-Key": APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/decision_maker?org_id=${event}&limit=50&skip=${(pageInner - 1) * 50
        }`,
    };
    return axios(option).then((e) => {
      if (e?.status === 200) {
        setTotalPages(10)
        const innerdata = e?.data?.data;
        if (innerdata?.length === 0) {
          setHasMoreInner(false);
        }
        return innerdata;
      }

      return e;
    });
  };

  const onLoadApi = (event) => {
    setopenRowId(event);

    const option = {
      method: "GET",
      headers: {
        "content-type": "plain/text",
        "X-API-Key": APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/decision_maker?org_id=${event}&limit=50&skip=${0}`,
    };
    return axios(option).then((e) => {
      if (e?.status === 200) {
        const innerdata = e?.data?.data;
        if (innerdata?.length === 0) {
          setHasMoreInner(false);
        } else {
        }
        return innerdata;
      }

      return e;
    });
  };
  const pushToSalesForce = () => {
    if (!validateSalesforce()) return;
    setLoading(true);

    const ms = {
      updateData: {
        data: selectedRows?.map((item) => ({
          items: [item?.person_id, "Suspect"],
        })),
      },
    };
    const transformedData = selectedRows
      ?.map((i) => {
        const organizationInfo =
          favoritesList.find((org) => org?.org_id === i?.org_id) || {};
        return { ...i, organizationInfo };
      })
      ?.map((item) => ({
        attributes: {
          type: "Lead",
          referenceId: "rec" + item?.person_id,
        },
        LastName: (item?.last_name),
        FirstName: (item?.first_name),
        Salutation: null,
        Email: item?.email,
        Phone: item?.phone_no,
        Title: item?.primary_job_title,
        Company: item?.primary_organization,
        City: item?.organizationInfo?.location_identifiers?.split(",")[0],
        State: item?.organizationInfo?.location_identifiers?.split(",")[1],
        Country: item?.organizationInfo?.country,
        LeadSource: "JOI",
        Status: "Suspect",
        LinkedIn_Profile__c: item?.linkedin,
        Suspect_Source_Detail__c: `${loggedUser?.firstName} ${loggedUser?.lastName}`,
      }));
    const finalJson = { records: transformedData, ...ms };
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      data: finalJson,
      url: `${APIUrlOne()}/v1/add_suspects`,
    };
    axios(option)
      .then(async (response) => {
        setLoading(false);
        if (response?.status === 200) {
          toast.success("Record has been created");
          const promiseRequest = selectedRows?.map((item) => {
            return ExpandHandler(item?.org_id, 1);
          });
          const result = await Promise.all(promiseRequest);
          setExpandHandlerAccept(...result);
          setSelectedRows([]);
          setMessageModalSalesforce(false)
          setIsSalesForceTrigger(false)
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };


    React.useEffect(() => {
      if (isSalesForceTrigger) {
        if (!validateSalesforce()) return;
        if (hitApiInner == true) {
          setMessageModalSalesforce(true)
          setIsSalesForceTrigger(false)
        }
      }
    }, [isSalesForceTrigger]);
    React.useEffect(() => {
      if (selectedRows?.length) {
        const data = selectedRows?.filter((item) => item.suspect_status !== null)
        if (data?.length) {
  
          setShowSalesForce("disable")
        } else {
          setShowSalesForce("show")
        }
      } else {
        setShowSalesForce(false)
      }
    }, [selectedRows])
  const handleRowSelect = (row) => {
    setSelectedOuterRows(prevSelectedRows => {
      const isAlreadySelected = prevSelectedRows.some(item => item.org_id === row.org_id);

      if (isAlreadySelected) {
        return prevSelectedRows.filter(item => item.org_id !== row.org_id);
      } else {
        return [...prevSelectedRows, row];
      }
    });
  };

  React.useEffect(() => {
    if (rowsSelectAll) {
      const allRows = [...archivesList];
      setSelectedOuterRows(allRows);
    } else if (rowsSelectAll === false) {
      setSelectedOuterRows([]);
    } else if (selectedOuterRows?.length == archivesList?.length) {
      setRowsSelectAll(true)
    } else if (selectedOuterRows?.length < archivesList?.length) {
      setRowsSelectAll(false)
    }
  }, [rowsSelectAll, rowsSelectOuter]);

  React.useEffect(() => {
    if (unArchivedRecord == true && apiHit == true) {
      handleArchiveRecords()
    }
  }, [unArchivedRecord])
  React.useEffect(() => {
    if (selectedOuterRows?.length) {
      setApiHit(true)
    } else {
      setApiHit(false)
    }
  }, [selectedOuterRows])
  const handleArchiveRecords = async () => {
    if (!selectedOuterRows?.length) {
      toast.error("Please select records to archive")
      setUnArchivedRecord(false)
      return
    } else {

      const data = archivesList?.filter(item => selectedOuterRows?.find(i => i?.id == item?.id))?.map(i => i?.archive_id)

      const options = {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          "content-type": "application/json",
          "X-API-Key": APIUrlToken()
        },
        data: data,
        url: `${APIUrlThree()}/v1/remove_archive`,
      };
      try {
        const response = await axios(options);
        toast.success("Organizations un-archived successfully")
        setUnArchivedRecord(false)
        listArchiveRecords()
      } catch (error) {
        // setLoading(false);
        toast.error(error?.response?.data?.message);
      } finally {
        // setLoading(false);
      }
    }
  }
  const listFavoriteRecords = async () => {
    const options = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      
      url: `${APIUrlThree()}/v1/list_favourite_leads?userid=${Number(GetUserId())}&limit=200&offset=0`,
    };
    try {
      const response = await axios(options);
      setFavoritesList(response?.data?.data)
    } catch (error) {
      // setLoading(false);
      toast.error(error?.response?.data?.message);
    } finally {
      // setLoading(false);
    }
  }
  const listArchiveRecords = async () => {
    const options = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/list_user_archive_leads?userid=${Number(GetUserId())}&limit=200&offset=0`,
    };
    try {
      const response = await axios(options);
      const data = response?.data?.data?.filter(item => item?.org_id !== null)
      setArchivesList(data)
    } catch (error) {
      // setLoading(false);
      toast.error(error?.response?.data?.message);
    } finally {
      // setLoading(false);
    }
  }
  React.useEffect(() => {
    listFavoriteRecords()
    listArchiveRecords()
  }, [])

  const handleTrackRecords = () => {
    if (!selectedOuterRows?.length) {
      toast.error("Please select records to track")
      setTrackRecord(false)
      return
    } else {
      setLoading(true);
      const data = selectedOuterRows.map(item => item?.org_id);
      const option = {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          "X-API-Key": APIUrlToken()
        },
        data: data,
        url: `${APIUrlThree()}/v1/save_tracking_organization?user_id=${Number(GetUserId())}`,
      };
      axios(option)
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            setTrackRecord(false)
            setSelectedOuterRows([])
            setMessageModalTrack(false)
            toast.success("Tracking Added")
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response.data.message);
        });
    }
  }
  React.useEffect(() => {
    if (selectedOuterRows?.length) {
      setHitApi(true)
    } else {
      setHitApi(false)
    }
  }, [selectedOuterRows])
  React.useEffect(() => {
    if (selectedRows?.length) {
      setHitApiInner(true)
    } else {
      setHitApiInner(false)
    }
  }, [selectedRows])
  React.useEffect(() => {
    if (trackRecord == true) {
      if (!selectedOuterRows?.length) {
        toast.error("Please select records to track")
        setTrackRecord(false)
        return
      } else if (hitApi == true) {
        setMessageModalTrack(true)
        setTrackRecord(false)
      }
      // handleTrackRecords()
    }
  }, [trackRecord])
  return (
    <React.Fragment>
      {loading ? <Loader /> : null}
      {archivesList?.length ? (
        <>
          <TableContainer component={Paper} className="ai-leads-table-main">
            <Box
              sx={{ margin: 1 }}
              id="scrollableDiv"
              className="ai-leads-table-inner_favourites"

            >
              <Table aria-label="collapsible table" className="ai-leads-table">
                <TableHead>
                  <TableRow className="table-row-ai-leads">
                    <TableCell className="checkbox-row-table-inner checkbox-row-table">
                      <div className="kjhgf">
                        {
                          showLeadsProspects==="pros" ?
                          <input
                            type="checkbox"
                           disabled
                          />:
                          <input
                            type="checkbox"
                            onChange={(e) => setRowsSelectAll(e?.target?.checked)}
                            checked={rowsSelectAll}
                          />
                        }
                      </div>
                    </TableCell>
                    <TableCell className="score-row-table">Favorite</TableCell>
                    <TableCell className="score-row-table">JOI Score</TableCell>
                    <TableCell align="left" className="company-row-table">
                      Company & location
                    </TableCell>
                    <TableCell align="left" className="employee-row-table">
                      Employee size
                    </TableCell>
                    <TableCell align="left" className="annual-row-table">
                      Annual revenue
                    </TableCell>
                    <TableCell align="left" className="industry-row-table">
                      <p className="sector-industry-inner-new">  Industry/ Sector </p>
                    </TableCell>
                    <TableCell align="left" className="industry-row-table">
                      <p className="industry-row-Inner">Employees</p>
                    </TableCell>
                    <TableCell align="left" className=""></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {archivesList?.map((row, index) => {
                    const isSelected = selectedRows?.find(
                      (item) => item?.person_id === row?.person_id
                    );
                    return (
                      <React.Fragment key={index}>
                        <Row
                          key={row.name}
                          row={row}
                          selected={isSelected}
                          onSelect={(selectedRow) => {
                            const localRows = [...selectedRows];
                            const selectedIndex = localRows.findIndex(
                              (row) => row?.person_id === selectedRow?.person_id
                            );
                            let newSelected = [];
                            if (selectedIndex === -1) {
                              newSelected = [selectedRow];
                            } else if (selectedIndex === 0) {
                              newSelected = newSelected.concat(
                                localRows.slice(1)
                              );
                            } else if (selectedIndex === localRows.length - 1) {
                              newSelected = newSelected.concat(
                                localRows.slice(0, -1)
                              );
                            } else if (selectedIndex > 0) {
                              newSelected = localRows.filter(
                                (child) =>
                                  child.person_id !== selectedRow.person_id
                              );
                            }
                            const updatedOrganizations = newSelected.map(
                              (name) => {
                                const organization = archivesList.find(
                                  (org) => org?.org_id === name?.org_id
                                );
                                return {
                                  ...name,
                                  organizationInfo: organization,
                                };
                              }
                            );
                            setSelectedRows(
                              selectedIndex > -1
                                ? updatedOrganizations
                                : [...selectedRows, ...updatedOrganizations]
                            );
                          }}
                          connectionStrength={row.connectionStrength}
                          ExpandHandler={ExpandHandler}
                          onLoadApi={onLoadApi}
                          expandHandlerAccept={expandHandlerAccept}
                          selectedRows={selectedRows}
                          setSelectedRows={setSelectedRows}
                          setPageInner={setPageInner}
                          pageInner={pageInner}
                          openRowId={openRowId}
                          hasMoreInner={hasMoreInner}
                          setExpandHandlerAccept={setExpandHandlerAccept}
                          setopenRowId={setopenRowId}
                          setHasMoreInner={setHasMoreInner}
                          totalPages={totalPages}
                          setRowsSelectOuter={setRowsSelectOuter}
                          selectedOuterRows={selectedOuterRows}
                          favoritesList={favoritesList}
                          setFavoritesList={setFavoritesList}
                          listFavoriteRecords={listFavoriteRecords}
                          archivesList={archivesList}
                          listArchiveRecords={listArchiveRecords}
                          handleRowSelect={handleRowSelect}
                          showLeadsProspects={showLeadsProspects}
                        />
                      </React.Fragment>
                    );
                  })}

                </TableBody>

              </Table>
            </Box>
          </TableContainer>
        </>
      ) : (
        <div className="ai-leads-table-main">
          <div className="ai-leads-no-data-available-outter">
            <div className="ai-leads-no-data-available">
              No Data Available
            </div>
          </div>
        </div>
      )
      }
      <MessageModal open={messageModalSalesforce} setOpen={setMessageModalSalesforce} message={`Are you sure that you want to push ${selectedRows?.length>1 ?"these records":"this record"} to Salesforce?`} handleSubmit={pushToSalesForce} length={selectedRows?.length} />
      <MessageModal open={messageModalTrack} setOpen={setMessageModalTrack} message={`Are you sure that you want to add ${selectedOuterRows?.length>1? "these records":"this record"} to tracking?`} handleSubmit={handleTrackRecords} length={selectedOuterRows?.length} />
    </React.Fragment >
  );
}
