import * as React from 'react';
import "./Style.css"
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import EmployeesTable from '../../../Pages/AiLeads/CompanyProfileScreen/EmployeesTable/Index';
import Comingsoon from '../../ComingSoon/ComingSoon';
import Crie from './Crie/Crie';
import callImg from "../../../Assets/call.svg";
import LoctionImg from "../../../Assets/loction.svg";
import userprofile from "../../../Assets/user.svg";
import GrowIcons from "../../../Assets/grow.svg";
import Companyimg from "../../../Assets/company.svg";
import GlobalImg from "../../../Assets/Globe.svg";
import IndustryImage from "../../../Assets/office-building.png";
import { Link } from "react-router-dom/dist";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import SpeedIcon from '@mui/icons-material/Speed';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export default function CompanyProfileTabs({ dataForInformation, rowData, handleToPDF, isDecisionMakerExcel, setIsDecisionMakerExcel, setIsSalesForceTrigger, isSalesForceTrigger, archiveRecord, setArchiveRecord, trackRecord, setTrackRecord, setDisableRequestUpdate, setRequestUpdateRecord, requestUpdateRecord, setLeadsRequestModal, value, setValue, handleToPDFCrie, handlePrint,setShowSalesForce }) {    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleMenuDownloadExcel = () => {
        setIsDecisionMakerExcel(true);
        setAnchorEl(null);
    }
    const isBranchLocationsDisabled = true;
    return (
        <Box className="company-profile-main-tabs-data" sx={{ width: '100%' }}>
            <div className="cp_outer_container_leads">
                <div className="cp_inner_container_leads">
                    <section className="">
                        <div className=" CompanyProfile-outter-container">
                            <div className="CompanyProfile-flex-container">
                                <div className="CompanyProfile-flex-outter-container">
                                    <div className="CompanyProfile-img">
                                        <img
                                            src={IndustryImage}
                                            style={{ width: "100%", height: "100%" }}
                                        />
                                    </div>
                                    <div>
                                        <h3 className="Netflix-heading">
                                            {dataForInformation?.name ? dataForInformation?.name : "-"}
                                        </h3>
                                        <div className="CompanyProfile-content">
                                            <img
                                                className="CompanyProfile-icons"
                                                src={GlobalImg}
                                                alt=""
                                            />
                                            {dataForInformation?.website_url ?
                                                <Link
                                                    to={dataForInformation?.website_url}
                                                    target="_blank"
                                                    className="clickable-link-for-website"
                                                >
                                                    <p>
                                                        {dataForInformation?.website_url?.split("www.")?.[1]
                                                            ? dataForInformation?.website_url?.split("www.")?.[1]
                                                            : dataForInformation?.website_url?.split("http://")?.[1]
                                                                ? dataForInformation?.website_url?.split("http://")?.[1] : dataForInformation?.website_url?.split("https://")?.[1]
                                                                    ? dataForInformation?.website_url?.split("https://")?.[1] : dataForInformation?.website_url
                                                        }
                                                    </p>
                                                </Link> : <p>Not Available</p>}
                                        </div>
                                        <div className="CompanyProfile-content">
                                            <img className="CompanyProfile-icons" src={callImg} alt="" />
                                            <p>
                                                {dataForInformation?.phone_number
                                                    ? dataForInformation?.phone_number
                                                    : "Not Available"}
                                            </p>
                                        </div>
                                        <div className="CompanyProfile-content">
                                            <img
                                                className="CompanyProfile-icons"
                                                src={LoctionImg}
                                                alt=""
                                            />
                                            <p>{`${dataForInformation?.street
                                                ? dataForInformation?.street + `,`
                                                : dataForInformation?.street === null
                                                    ? ""
                                                    : dataForInformation?.street
                                                } ${dataForInformation?.location_identifiers
                                                    ? dataForInformation?.location_identifiers + `,`
                                                    : dataForInformation?.location_identifiers === null
                                                        ? ""
                                                        : dataForInformation?.location_identifiers
                                                } ${dataForInformation?.postalcode
                                                    ? dataForInformation?.postalcode + `,`
                                                    : dataForInformation?.postalcode === null
                                                        ? ""
                                                        : dataForInformation?.postalcode
                                                } ${dataForInformation?.country
                                                    ? dataForInformation?.country
                                                    : dataForInformation?.country === null
                                                        ? ""
                                                        : dataForInformation?.country
                                                }`}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="CompanyProfile-cont-rightside">
                                    <div className="CompanyProfile-content">
                                        {/* <img className="CompanyProfile-icons" src={callImg} alt="" /> */}
                                        <SpeedIcon className="CompanyProfile-icons ai-score-icon" />
                                        <p>
                                        JOI Score -
                                            {dataForInformation?.ai_score !== null && dataForInformation?.ai_score !== undefined
                                                ? dataForInformation?.ai_score
                                                : "NA"}
                                        </p>
                                    </div>
                                    <div className="CompanyProfile-content">
                                        <img
                                            className="CompanyProfile-icons"
                                            src={userprofile}
                                            alt=""
                                        />
                                        <p>
                                            Employees-
                                            {dataForInformation?.num_employees
                                                ? dataForInformation?.num_employees
                                                : "Not Available"}
                                        </p>
                                    </div>
                                    <div className="CompanyProfile-content">
                                        <img className="CompanyProfile-icons" src={GrowIcons} alt="" />
                                        <p>
                                            Annual revenue -
                                            {dataForInformation?.revenue_range
                                                ? dataForInformation?.revenue_range
                                                : "Not Available"}
                                        </p>
                                    </div>
                                    <div className="CompanyProfile-content">
                                        <img className="CompanyProfile-icons" src={Companyimg} alt="" />
                                        <p className="industry-mentions">
                                            Industry/ Sector - {dataForInformation?.categories?.split(/(?<=,)|\s+/) // Split by spaces while keeping commas intact
                                                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                                .join(" ")}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="CompanyProfile-infomation-main">
                                <div>
                                    <Accordion defaultExpanded>
                                        <AccordionSummary
                                            expandIcon={<KeyboardArrowDownIcon />}
                                            aria-controls="panel2-content"
                                            id="panel2-header"
                                        >
                                            <Typography className="company_bio_trio">Company Bio</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography className="CompanyProfile-infomation">
                                                <p className="industry-mentions">
                                                    {dataForInformation?.description
                                                        ? dataForInformation.description.replace(
                                                            /(^|\.\s+)([a-z])/g,
                                                            (match) => match.toUpperCase()
                                                        )
                                                        : "Not Available"}
                                                </p>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs className='Companydata' value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab
                                label="Employees " {...a11yProps(0)} />
                            <Tab label="Company and Relationship Intelligence Explorer (CRIE)" {...a11yProps(1)} />
                            <Tab label="News/Events" {...a11yProps(2)} />
                            {/* <Tab label="News/ Events" {...a11yProps(3)} /> */}
                        </Tabs>
                    </Box>
                </div>
            </div>
            <CustomTabPanel value={value} index={0} className="company_tabs_data_cont">
                <EmployeesTable dataForInformation={dataForInformation} rowData={rowData} setIsDecisionMakerExcel={setIsDecisionMakerExcel}
                    isDecisionMakerExcel={isDecisionMakerExcel}
                    archiveRecord={archiveRecord} setArchiveRecord={setArchiveRecord}
                    trackRecord={trackRecord} setTrackRecord={setTrackRecord}
                    setDisableRequestUpdate={setDisableRequestUpdate} requestUpdateRecord={requestUpdateRecord} setRequestUpdateRecord={setRequestUpdateRecord}
                    setLeadsRequestModal={setLeadsRequestModal} setIsSalesForceTrigger={setIsSalesForceTrigger} isSalesForceTrigger={isSalesForceTrigger} setShowSalesForce={setShowSalesForce} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1} disabled={isBranchLocationsDisabled} className="company_tabs_data_cont">
                {/* Branch locations */}
                <Crie dataForInformation={dataForInformation} handleToPDFCrie={handleToPDFCrie} handlePrint={handlePrint} />
                {/* <Comingsoon withoutLayout={true} /> */}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2} disabled={isBranchLocationsDisabled} className="company_tabs_data_cont">
                {/* Similar companies */}
                <Comingsoon dataForInformation={dataForInformation} />
            </CustomTabPanel>
            {/* <CustomTabPanel value={value} index={3} disabled={isBranchLocationsDisabled}>
                <Comingsoon withoutLayout={true} />
            </CustomTabPanel> */}
        </Box>
    );
}
