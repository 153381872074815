import React, { useEffect, useState } from 'react'
import Layout from '../../Components/Layout/Layout'
import "./DataEnrich.css";
import DataEnrichUploadModal from '../../Components/DataEnrich/EnrichProspect/DataEnrichUploadModal';
import DataEnrichCard from '../../Components/DataEnrich/DataEnrichCard';
import DataEnrichOrgUploadModal from '../../Components/DataEnrich/EnrichOrganization/DataEnrichOrgUploadModal';
import DataEnrichTable from '../../Components/DataEnrich/DataEnrichTable';
import { APIUrlThree, APIUrlToken, GetUserId } from '../../Utils/Utils';
import Loader from '../../Components/Loader/Loader';
import axios from 'axios';
const DataEnrich = () => {
  const [uploadModal, setUploadModal] = useState(false)
  const [orgUploadModal, setOrgUploadModal] = useState(false)
  const [enrichData, setEnrichData] = useState([]);
  const [loading, setLoading] = useState(false);

  const userId = GetUserId();
  const getEnrichList = () => {
    const option = {
        method: "GET",
        headers: {
            "access-control-allow-origin": "*",
            "content-type": "application/json",
            "X-API-Key": APIUrlToken()
        },
        url: `${APIUrlThree()}/v1/get_enrich_request?user_id=${userId}`,
    };
    axios(option)
        .then((e) => {
            setLoading(false);
            if (e?.status === 200) {
                const sortedData = e?.data?.data
                    ?.filter(item => item?.user_filename !== null)
                    ?.sort((a, b) => new Date(b.requested_timestamp) - new Date(a.requested_timestamp));
                setEnrichData(sortedData);
            }
        })
        .catch(() => {
            setLoading(false);
        });
};
useEffect(() => {
    getEnrichList();
}, []);
  return (
    <>
      {loading && <Loader />}
      <Layout className={"paddingSet"}>
        <DataEnrichCard setUploadModal={setUploadModal} uploadModal={uploadModal} setOrgUploadModal={setOrgUploadModal} orgUploadModal={orgUploadModal} />
        <DataEnrichTable enrichData={enrichData} setLoading={setLoading} getEnrichList={getEnrichList} />
        <DataEnrichUploadModal modalOpen={uploadModal} handleClose={() => setUploadModal(false)} setUploadModal={setUploadModal} getEnrichList={getEnrichList} />
        <DataEnrichOrgUploadModal modalOpen={orgUploadModal} handleClose={() => setOrgUploadModal(false)} setOrgUploadModal={setOrgUploadModal} getEnrichList={getEnrichList} />
      </Layout>
    </>
  )
}
export default DataEnrich